<template>
  <div class="full-width full-height">
    <div class="hd flex items-center justify-between m-b-sm">
      <div class="flex">
        <title-com text="事件统计" />
        <span>
          <i
            class="icon-line pointer m-r-sm"
            :class="{ on: type === 'line' }"
            @click="selectChart('line')"
          ></i>
          <i
            class="icon-bar pointer"
            :class="{ on: type === 'bar' }"
            @click="selectChart('bar')"
          ></i>
        </span>
      </div>
      <div class="flex-one">
        <div v-show="type === 'line'" class="flex flex-one flex-end">
          <a-select
            v-model="lineParam.value"
            class="m-r-sm search-mx-wd-1"
            @change="handleChange"
          >
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option
              v-for="item in typeNames"
              :key="item.id"
              :value="item.id"
            >
              {{ item.eventName }}
            </a-select-option>
          </a-select>
          <div class="flex fl-menu m-r-sm" style="width:160px;">
            <span
              class="pointer m-r-sm f12 ellipsis"
              :class="{
                current: lineParam.flag === 0 && lineParam.eventAlarmDays === 7,
              }"
              @click="selectLineData(0, 7)"
              >近7日</span
            >
            <span
              class="m-r-sm pointer f12 ellipsis"
              :class="{
                current:
                  lineParam.flag === 0 && lineParam.eventAlarmDays === 30,
              }"
              @click="selectLineData(0, 30)"
              >近30日</span
            >
            <span
              class="pointer f12 ellipsis"
              :class="{
                current:
                  lineParam.flag === 1 && lineParam.eventAlarmDays === null,
              }"
              @click="selectLineData(1, null)"
              >按月统计</span
            >
          </div>
          <!-- <a-range-picker
            v-model="lineParam.datePicker"
            @change="onDateChange"
            style="width:34%;"
          /> -->
        </div>
        <div v-show="type === 'bar'" class="flex flex-one flex-end">
          <div class="flex fl-menu m-r-sm" style="width:160px;">
            <span
              class="pointer m-r-sm"
              :class="{ current: barParam.eventAlarmDays === 0 }"
              @click="selectBarMenu(0)"
              >今日</span
            >
            <span
              class="pointer m-r-sm"
              :class="{ current: barParam.eventAlarmDays === 7 }"
              @click="selectBarMenu(7)"
              >近7日</span
            >
            <span
              class="pointer"
              :class="{ current: barParam.eventAlarmDays === 30 }"
              @click="selectBarMenu(30)"
              >近30日</span
            >
          </div>
          <!--  <a-range-picker
            v-model="barParam.datePicker"
            @change="onDateBarChange"
            style="width:34%;"
          
          /> -->
        </div>
      </div>
    </div>
    <div class="full-width" style="height:calc(100% - 40px);">
      <template v-if="!showLoading">
        <event-chart-line v-if="type === 'line'" :data="lineData" />
        <event-chart-bar v-if="type === 'bar'" :data="barData" />
      </template>
      <span
        class="flex full-width full-height items-center justify-center"
        v-show="showLoading"
      >
        <a-spin></a-spin>
      </span>
    </div>
  </div>
</template>

<script>
import TitleCom from './TitleCom';
import EventChartLine from '@/components/EventChartLine';
import EventChartBar from '@/components/EventChartBar';
import moment from 'moment';
export default {
  name: 'BotMain',
  data() {
    return {
      type: 'line',
      lineParam: {
        value: '',
        eventAlarmDays: 7,
        flag: 0,
        startTime: null,
        endTime: null,
        datePicker: null,
      },
      // 日期
      barParam: {
        eventAlarmDays: 0,
        startTime: null,
        endTime: null,
        datePicker: null,
      },
      lineData: [],
      barData: [],
      typeNames: [],
    };
  },
  computed: {
    showLoading() {
      return (
        this.type === 'line' &&
        this.lineData.length <= 0 &&
        this.type === 'bar' && this.barData.length <= 0
      );
    },
  },
  components: {
    TitleCom,
    EventChartLine,
    EventChartBar,
  },
  watch: {
    // 监听事件变化，newValue   oldValue getEventInfoByDay
  },
  created() {
    this._getTypeName();
    this._getLineStatistic();
  },
  methods: {
    _getTypeName() {
      this.$api.aiCenterY.getTypeNameAll('1001').then((res) => {
        if (res.code === 200) {
          // console.error(res);
          this.typeNames = res.data;
        }
      });
    },
    selectChart(chart) {
      this.type = chart;
      switch (this.type) {
        case 'line':
          this._initiateLine();
          break;
        case 'bar':
          this._initiateBar();
          break;
      }
    },
    _initiateLine() {
      this.lineParam.eventAlarmDays = 7;
      this.lineParam.flag = 0;
      this.lineParam.startTime = null;
      this.lineParam.endTime = null;
      this.lineParam.datePicker = null;
      this._getLineStatistic();
    },
    _initiateBar() {
      this.barParam.eventAlarmDays = 0;
      this.barParam.startTime = null;
      this.barParam.endTime = null;
      this.barParam.datePicker = null;
      this._getBarStatistic();
    },
    selectLineData(flag, eventAlarmDays) {
      this.lineParam.flag = flag;
      this.lineParam.eventAlarmDays = eventAlarmDays;
      this.lineParam.startTime = null;
      this.lineParam.endTime = null;
      this.lineParam.datePicker = null;
      this.lineParam.value = '';
      this._getLineStatistic();
    },
    selectBarMenu(day) {
      this.barParam.eventAlarmDays = day;
      this.barParam.startTime = null;
      this.barParam.endTime = null;
      this.barParam.datePicker = null;
      this._getBarStatistic();
    },
    _getBarStatistic() {
      let params = {
        // endTime: this.barParam.endTime,
        eventAlarmDays: this.barParam.eventAlarmDays,
        // startTime: this.barParam.startTime,
      };
      this.$api.aiCenterY.getEventHistogramStatistics(params).then((res) => {
        if (res.code === 200) {
          this.barData = res.data.map((item) => {
            return {
              name: item.typeName,
              value: Number(item.eventAlarmNumber),
            };
          });
        }
      });
    },
    _getLineStatistic() {
      /* let params = {
        endTime: this.lineParam.endTime,
        eventAlarmDays: this.lineParam.eventAlarmDays,
        eventAlarmType: this.lineParam.value,
        flag: this.lineParam.flag,
        startTime: this.lineParam.startTime,
      };
      this.$api.aiCenterY.getEventAlarmStatistics(params).then((res) => {
        if (res.code === 200) {
          let data = res.data;
          this.lineData = data.map((item) => {
            return {
              name: item.statisticsTime,
              value: Number(item.eventAlarmNumber),
            };
          });
        }
      }); */
      let params = {
        url: {
          eventType: this.lineParam.value,
          days:
            this.lineParam.flag == 0
              ? this.lineParam.eventAlarmDays
              : undefined,
        },

        timeType: this.lineParam.flag == 0 ? 'day' : 'month',
        /* flag: this.lineParam.flag, */
      };

      this.$api.aiCenterY.getEventInfoByDay(params).then((res) => {
        if (res.code === 200) {
          let data = res.data;
          this.lineData = data.map((item) => {
            return {
              name: item.statisticalTime,
              value: Number(item.eventNumber),
            };
          });
        }
      });
    },
    handleChange(e) {
      this.lineParam.value = e;
      this._getLineStatistic();
    },
    onDateChange(date) {
      this.lineParam.datePicker = date;
      this.lineParam.startTime = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
      this.lineParam.endTime = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');
      this.lineParam.flag = 0;
      this.lineParam.eventAlarmDays = null;
      this._getLineStatistic();
    },
    onDateBarChange(date) {
      this.barParam.datePicker = date;
      this.barParam.startTime = moment(date[0]).format('YYYY-MM-DD HH:mm:ss');
      this.barParam.endTime = moment(date[1]).format('YYYY-MM-DD HH:mm:ss');
      this.barParam.eventAlarmDays = null;
      this._getBarStatistic();
    },
  },
};
</script>

<style scoped>
.fl-menu span.m-r-sm {
  margin-right: 5px;
}
</style>
