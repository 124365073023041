<template>
  <!--千方地图-->
  <div class="qf-map_wrap">
    <!--地图容器-->
    <div class="qf-map-box" id="mapCon"></div>

    <!--弹出框-->
    <div id="popup" class="ol-popup">
      <div id="popup-content"></div>
    </div>

    <div class="gd-map" id="gdMap"></div>
    <event-alarm-detail
      ref="eventAlarm"
      :visible="eventVisible"
      :cameraDetailId="cameraDetailId"
      type="map"
      sourceType="cameraNum"
      @close="closeDetail"
      @misReportHandle="misReportParentHandle"
    />
  </div>
</template>

<script>
import { wgs84_to_gcj02, gcj02_to_wgs84 } from '@/tools/wgs84_to_gcj02.js';
import EventAlarmDetail from '@/components/EventAlarmDetail';
import { mapMutations } from 'vuex';

import * as olProj from 'ol/proj';

import TileGrid from 'ol/tilegrid/TileGrid';
import TileWMS from 'ol/source/TileWMS';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import Map from 'ol/Map';
import * as format from 'ol/format';
import * as olLoadingstrategy from 'ol/loadingstrategy';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Overlay from 'ol/Overlay';
import XYZ from 'ol/source/XYZ';
import MultiLineString from 'ol/geom/MultiLineString';
// import EventAlarmDetail from '@/components/EventAlarmDetail';
const AMap = window.AMap;
const AMapUI = window.AMapUI;

export default {
  components: {
    // amapPlayer,
    // EventAlarmDetail,
    EventAlarmDetail,
  },
  props: {
    cameraTypeSelect: {},
    cameraStatusChange: {},
  },
  data() {
    let satellite = null;

    let geoserverUrl = 'https://gis-t.chinahighway.org.cn:8001';
    let trafficUrl = 'http://39.96.167.193:8003';
    let roadNet = new TileLayer({
      name: '71118路网',
      source: new TileWMS({
        url: geoserverUrl + '/geoserver/sf/wms',
        //projection: 'EPSG:4326',
        params: {
          LAYERS: '71118',
          VERSION: '1.0.0',
          TILED: true,
        },
        serverType: 'geoserver',
        wrapX: false,
      }),
      zIndex: 10,
    });
    let trafficLayer = new TileLayer({
      name: '路况',
      source: new XYZ({
        url: trafficUrl + '/TrafficTile?x={x}&y={y}&zoom={z}',
        wrapX: false,
      }),
      zIndex: 10,
    });
    return {
      satellite,
      roadNet,
      trafficLayer,
      cameraMarkVisable: false,
      fsssWfsUrl: 'https://gis-t.chinahighway.org.cn:8001',
      geoserverUrl,
      geowebcacheUrl: 'https://gis-t.chinahighway.org.cn:8001',
      trafficUrl,
      opacity_color: 0,
      layer: [], // map中的图层数组
      layerName: [], // 图层名称数组
      layerVisibility: [], // 图层可见属性数组
      lwzxbkMapLayer: null,
      lwzxrouteMapLayer: null,
      lwzxblueMapLayer: null,
      lwzxzhujiMapLayer: null,
      trffic_layer: null,
      style_line: null,
      projection: olProj.get('EPSG:3857'),
      vectorSource: null,
      vectorLayer: null,
      view: null,
      mapInstance: null,
      wmsVectorLayer_china: null, // 国界面图层
      wmsVectorLayer_71118: null, // 71118图层
      wmsVectorLayer_road: null, // 高速
      wmsVectorLayer_tollplaza: null, // 收费站广场
      wmsVectorLayer_fsss_chukou: null, // 出口
      wmsVectorLayer_fsss_rukou: null, // 入口
      wmsVectorLayer_fsss_suidao: null, // 隧道
      wmsVectorLayer_fsss_gasstation: null, // 加油站
      wmsVectorLayer_fsss_keyunzhan: null, // 客运站
      wmsVectorLayer_fsss_tingchequ: null, // 停车区
      wmsVectorLayer_fsss_qiaoliang: null, // 桥梁
      wmsVectorLayer_fsss_video: null, // 视频
      wmsVectorLayer_fsss_tollpoint: null, // 收费门架
      wmsVectorLayer_fsss_tollunit: null, // 收费单元
      wmsVectorLayer_province: null, // 省界面
      wmsVectorLayer_ksmatch: null, // 里程桩

      cameraSource: null, // 摄像机
      cameraLayer: null, // 摄像机
      cameraImg: require('@/assets/traffic_map/icon/camera-panorama-gif.gif'),

      vectorLayer_point: null,

      //摄像机选中图标
      cameraGifUrl: {
        '1': require('@/assets/traffic_map/icon/camera-gif.gif'),
        '2': require('@/assets/traffic_map/icon/camera-gif-2.gif'),
        '3': require('@/assets/traffic_map/icon/camera-gif-3.gif'),
        '4': require('@/assets/traffic_map/icon/camera-gif-5.gif'),
        '5': require('@/assets/traffic_map/icon/camera-gif-5.gif'),
        '6': require('@/assets/traffic_map/icon/camera-gif-6.gif'),
        '7': require('@/assets/traffic_map/icon/camera-gif-7.gif'),
      },
      //摄像机正常图标
      cameraImgUrl: {
        '0': require('@/assets/traffic_map/icon/newIcon/road-online.png'),
        '1': require('@/assets/traffic_map/icon/newIcon/road-online.png'),
        '2': require('@/assets/traffic_map/icon/newIcon/bridge-online.png'),
        '3': require('@/assets/traffic_map/icon/newIcon/tunnel-online.png'),
        '4': require('@/assets/traffic_map/icon/newIcon/toll-online.png'),
        '5': require('@/assets/traffic_map/icon/newIcon/service-online.png'),
        '6': require('@/assets/traffic_map/icon/newIcon/etc-online.png'),
        '7': require('@/assets/traffic_map/icon/newIcon/car-online.png'),
        '8': require('@/assets/traffic_map/icon/newIcon/reserve-online.png'),
      }, //require('@/assets/images/traffic_map/icon/camera.png'),

      //摄像机离线图标
      cameraOfflineImgUrl: {
        '0': require('@/assets/traffic_map/icon/newIcon/road-offline.png'),
        '1': require('@/assets/traffic_map/icon/newIcon/road-offline.png'),
        '2': require('@/assets/traffic_map/icon/newIcon/bridge-offline.png'),
        '3': require('@/assets/traffic_map/icon/newIcon/tunnel-offline.png'),
        '4': require('@/assets/traffic_map/icon/newIcon/toll-offline.png'),
        '5': require('@/assets/traffic_map/icon/newIcon/service-offline.png'),
        '6': require('@/assets/traffic_map/icon/newIcon/etc-offline.png'),
        '7': require('@/assets/traffic_map/icon/newIcon/car-offline.png'),
        '8': require('@/assets/traffic_map/icon/newIcon/reserve-offline.png'),
      }, //require('@/assets/images/traffic_map/icon/camera-offline.png'),
      //摄像机故障图标
      cameraErrorImgUrl: {
        '0': require('@/assets/traffic_map/icon/newIcon/road-error.png'),
        '1': require('@/assets/traffic_map/icon/newIcon/road-error.png'),
        '2': require('@/assets/traffic_map/icon/newIcon/bridge-error.png'),
        '3': require('@/assets/traffic_map/icon/newIcon/tunnel-error.png'),
        '4': require('@/assets/traffic_map/icon/newIcon/toll-error.png'),
        '5': require('@/assets/traffic_map/icon/newIcon/service-error.png'),
        '6': require('@/assets/traffic_map/icon/newIcon/etc-error.png'),
        '7': require('@/assets/traffic_map/icon/newIcon/car-error.png'),
        '8': require('@/assets/traffic_map/icon/newIcon/reserve-error.png'),
      }, //require('@/assets/images/traffic_map/icon/camera-error.png'),
      //摄像机报警
      cameraAlarm: {
        '0': require('@/assets/traffic_map/icon/alarm/camera_plan.png'),
        '1': require('@/assets/traffic_map/icon/alarm/camera_alarm.gif'),
      },
      popupOverlay: null,
      camVectorLayer: [],
      currentPlayCamera: {},
      converVectorLayer: null,
      actCamvectorLayer: null,
      converLayer: null,

      gdMap: null, // 高德地图
      districtExplorer: null, // 高德地图区域
      initZoom: 3.5, // 默认加载层级
      initCenter: olProj.fromLonLat([107.408718, 34.922369]), // 默认加载中心点
      getCamPostBody: {}, // 获取摄像机列表请求参数
      actProvinceId: 100000,

      container: document.getElementById('popup'),
      popupContent: document.getElementById('popup-content'),
      chosedMarkData: {},
      chosedMark: null,
      backMark: null,
      mapLayerType: 'base',
      mark_b: require('@/assets/traffic_map/mark_b.png'),
      currentPosMarker: null,
      currPolyline: null,
      eventVisible: false,
      cameraDetailId: '',
    };
  },
  methods: {
    ...mapMutations(['setSelectCameraIndex', 'setLatResult', 'setDragMove']),
    /**
     * 地图加载
     */
    mapLoad() {
      var _this = this;

      // 地图图层初始化配置
      this.view = new View({
        projection: _this.projection, // 坐标系如果不设置，默认为墨卡托
        center: _this.initCenter, // 设置地图中心点
        zoom: _this.initZoom, // 初始化显示层级
        minZoom: 3, // 最小显示层级
        maxZoom: 16, // 最大显示层级
        // constrainResolution: true,           // 因为存在非整数的缩放级别，所以设置该参数为true来让每次缩放结束后自动缩放到距离最近的一个整数级别，这个必须要设置，当缩放在非整数级别时地图会糊
      });
      this.mapInstance = new Map({
        target: 'mapCon', // 容器id
        layers: [
          /*  _this.trafficLayer, */
          // _this.lwzxbkMapLayer,
          // _this.lwzxrouteMapLayer,
          /* _this.roadNet,fixsxy */
          _this.lwzxzhujiMapLayer,
          _this.lwzxblueMapLayer,
          _this.wmsVectorLayer_china,
          // _this.wmsVectorLayer_71118,
        ], // 默认加载图层
        view: _this.view,
      });
      this.mapInstance.on('complete',()=>{
        this.$emit('amapChanged');
      });
      
      /**
       * 地图悬浮时触发
       */
      this.mapInstance.on('pointermove', function(e) {
        clearTimeout(window.pointermoveTimer);

        window.pointermoveTimer = setTimeout(() => {
          var pixel = _this.mapInstance.getEventPixel(e.originalEvent);

          // 摄像机标志（每次点击只能触发一次）
          var camFlag = true;

          if (_this.popupOverlay) {
            _this.popupOverlay.setPosition(undefined);
          }

          _this.mapInstance.forEachFeatureAtPixel(pixel, function(feature) {
            if (!camFlag) {
              return;
            }

            // 获取摄像机自定义信息
            var obj = feature.getProperties();

            //coodinate存放了点击时的坐标信息
            var coodinate = e.coordinate;

            // let wgS84 = _this.mercatorTolonlat({ x: coodinate[0], y: coodinate[1] })
            //
            // let gcj02 = _this.$gcoord.transform([wgS84.x, wgS84.y], _this.$gcoord.WGS84, _this.$gcoord.GCJ02);

            // let container = document.getElementById("popup");
            // let content = document.getElementById("popup-content");
            // let popupCloser = document.getElementById("popup-closer");

            _this.popupOverlay = new Overlay({
              //设置弹出框的容器
              element: _this.container,
              positioning: 'top-left',
              offset: [10, 10],
              //是否自动平移，即假如标记在屏幕边缘，弹出时自动平移地图使弹出框完全可见
              autoPan: false,
            });

            camFlag = false;
            /*  if (obj.type === 'dragData') {
              if (_this.chosedMark) {
                _this.chosedMark.show();
              }
              // console.error(a.pixel);
              _this.$refs.dragMarkerGhost.$el.style.top = `${100 -
                30 +
                pixel[1]}px`;
              _this.$refs.dragMarkerGhost.$el.style.left = `${320 +
                30 +
                pixel[0]}px`;

              _this.addEventMarker(obj, true);
              return;
            } */
            // 未携带名称的图层表示不是摄像机图层，则显示省份信息
            if (!obj.name) {
              _this.getAreaCode(coodinate).then(({ data }) => {
                if (_this.actProvinceId !== 100000) {
                  var valName = data.data.city
                    ? data.data.city.value
                    : data.data.county.value;

                  if ((_this.actProvinceId + '').slice(2, 6) !== '0000') {
                    valName = data.data.county.value;
                  }

                  _this.popupContent.innerHTML = `<div>${valName}</div>`;
                } else {
                  _this.popupContent.innerHTML = `<div>${data.data.province.value}</div>`;
                }

                //设置overlay的显示位置
                _this.popupOverlay.setPosition(coodinate);
                //显示overlay
                _this.mapInstance.addOverlay(_this.popupOverlay);
              });

              // _this.$axios.get( _this.geoserverUrl + `/API/getInverseGeocoding.do?lat=${ gcj02[1] }&lng=${ gcj02[0] }` ).then(( { data } )=> {
              //     console.log("data===》",data);
              //     //设置弹出框内容，可以HTML自定义
              //     if( _this.actProvinceId !== 100000 ) {
              //         _this.popupContent.innerHTML = `<div>${ data.data.city?data.data.city.value:data.data.county.value }</div>`;
              //     }else {
              //         _this.popupContent.innerHTML = `<div>${ data.data.province.value }</div>`;
              //     }
              //
              //     //设置overlay的显示位置
              //     _this.popupOverlay.setPosition(coodinate);
              //     //显示overlay
              //     _this.mapInstance.addOverlay(_this.popupOverlay);
              // })
              return;
            }

            //设置弹出框内容，可以HTML自定义
            _this.popupContent.innerHTML = `<div>${obj.khPile}</div>`;
            //设置overlay的显示位置
            _this.popupOverlay.setPosition(coodinate);
            //显示overlay
            _this.mapInstance.addOverlay(_this.popupOverlay);
            // _this.getFeatureEvent(pixel);
          });
        }, 200);
      });
      /**
       * 地图添加点击事件
       */
      this.mapInstance.on('singleclick', function(e) {
        var pixel = _this.mapInstance.getEventPixel(e.originalEvent);

        // 摄像机标志（每次点击只能触发一次）
        var camFlag = true;

        clearTimeout(window.singleclickTimer);

        // 当点击区域超出蒙版范围时，获取省份code加载外部区域
        window.singleclickTimer = setTimeout(function() {
          //coodinate存放了点击时的坐标信息
          var coodinate = e.coordinate;

          _this.getAreaCode(coodinate).then(({ data }) => {
            if (_this.actProvinceId === 100000) {
              _this.getCamPostBody.provinceCode = 100000;

              // _this.addConverLayer(100000, _this.getCamPostBody, true);
            } else {
              _this.getCamPostBody.provinceCode =
                data.data.province.admcode * 1;

              /* _this.addConverLayer(
                data.data.province.admcode * 1,
                _this.getCamPostBody,
                data.data.province
              ); */
            }
          });
        }, 300);

        _this.mapInstance.forEachFeatureAtPixel(pixel, function(feature) {
          clearTimeout(window.singleclickTimer);

          if (!camFlag) {
            return;
          }

          // 获取摄像机自定义信息
          var cameraInfo = feature.getProperties();

          //coodinate存放了点击时的坐标信息
          var coodinate = e.coordinate;
          console.log('haha', cameraInfo);

          if (cameraInfo.cameraType === '1') {
            _this.eventVisible = true;
            _this.cameraDetailId = cameraInfo.cameraNum;
          } else {
            // _this.$message.error('离线视频不能播放');
          }
          if (!cameraInfo.name) {
            if (
              [110000, 120000, 310000, 500000].indexOf(
                _this.actProvinceId * 1
              ) !== -1
            ) {
              return;
            }

            _this.getAreaCode(coodinate).then(({ data }) => {
              if (_this.actProvinceId === 100000) {
                _this.getCamPostBody.provinceCode =
                  data.data.province.admcode * 1;

                /*  _this.addConverLayer(
                  data.data.province.admcode * 1,
                  _this.getCamPostBody,
                  data.data.province
                ); */
              } else if ((_this.actProvinceId + '').slice(2, 6) === '0000') {
                _this.getCamPostBody.provinceCode = data.data.city
                  ? data.data.city.admcode * 1
                  : data.data.county.admcode * 1;

                /*   _this.addConverLayer(
                  data.data.city
                    ? data.data.city.admcode * 1
                    : data.data.county.admcode * 1,
                  _this.getCamPostBody,
                  data.data.city ? data.data.city : data.data.county
                ); */
              }
            });

            return;
          }

          camFlag = false;
        });
      });

      /**
       * 地图被放大或者缩小/移动后触发
       */
      // eslint-disable-next-line no-unused-vars
      this.mapInstance.on('moveend', (evt) => {
        /* if (window.setMapTimeout) {
          clearTimeout(window.setMapTimeout);
        }

        // 设置0.75秒防抖
        window.setMapTimeout = setTimeout(() => {
          _this.cameraLoad();
        }, 750); */
        this.mapChanged();
      });
    },
    closeDetail() {
      this.eventVisible = false;
      this.cameraDetailId = '';
    },
    misReportParentHandle() {
      // this.getTotayList(this.searchParams);
      this.$emit('misReportParent');
    },
    /**
     * marker标记点击效果
     * @param data
     * @param flag      true    播放状态添加
     *                  false   右击修改状态添加
     */
    addEventMarker(obj, flag = false) {
      let that = this,
        data = obj;
      if (!data) {
        return false;
      }

      //当前选中的摄像头
      // that.chosedMark = obj.target;
      // that.chosedMark.hide();
      that.chosedMarkData = data;
      // console.error('ppppppppppppp', this.chosedMarkData);
      //移除marker图标
      if (this.backMark) {
        this.mapInstance.removeLayer(this.backMark);
      }

      //矢量标注的数据源
      let vectorSource = new VectorSource();
      //矢量标注图层
      let eventCameraMarker = new VectorLayer({
        source: vectorSource,
        zIndex: 999,
      });
      this.mapInstance.addLayer(eventCameraMarker);

      //矢量标注样式设置函数，设置image为图标ol.style.Icon
      // eslint-disable-next-line no-unused-vars
      function createLabelStyle(feature) {
        return new Style({
          image: new Icon({
            anchor: [0, 0], //锚点
            anchorOrigin: 'top-right', //锚点源
            anchorXUnits: 'fraction', //锚点X值单位
            anchorYUnits: 'pixels', //锚点Y值单位
            offsetOrigin: 'center', //偏移原点
            opacity: 1,
            scale: 0.7,
            src: that.cameraGifUrl['1'], //图标的URL
          }),
        });
      }

      //新建一个要素ol.Feature
      var newFeature = new Feature({
        geometry: new Point(olProj.fromLonLat(data.lonlat)), //几何信息
        name: '',
      });
      newFeature.setStyle(createLabelStyle(newFeature)); //设置要素样式
      vectorSource.addFeature(newFeature);
      eventCameraMarker.on('mousedown', (e) => {
        //console.info('mousedown ',a,b,that.dragMarkerGhostStyle);
        eventCameraMarker.hide();
        //console.info(that.$refs.dragMarkerGhost);
        //that.dragMarkerGhostStyle = { display:"inline-block", top:e.pixel.y+80-15 + "px", left:e.pixel.x+406-18  + "px"};
        that.$refs.dragMarkerGhost.$el.style.top = e.pixel.y + 80 - 15 + 'px';
        that.$refs.dragMarkerGhost.$el.style.left = e.pixel.x + 406 - 18 + 'px';

        document.onmouseup = function() {
          /*鼠标放开清除事件*/
          that.dragMarkerGhostStyle = {};
          document.onmousemove = null;
          document.onmouseup = null;
          that.$root.$emit('removeEventMarker', '');
        };
      });
      this.backMark = eventCameraMarker;

      if (flag) {
        return;
      }
    },
    checkGoogleMapType(type) {
      if (type === 'google') {
        //this.mapLayerType = type;
        //this.districtExplorer.clearFeaturePolygons();
        // this.mapInstance.addLayer(this.googleLayer);
      }
      if (type === 'satellite') {
        this.mapInstance.addLayer(this.satellite);
      }
      if (type === 'roadNet') {
        this.mapInstance.addLayer(this.roadNet);
        this.mapInstance.removeLayer(this.googleLayer);
        this.mapInstance.removeLayer(this.satellite);
        this.mapInstance.removeLayer(this.trafficLayer);
      }
      if (type === 'trafficLayer') {
        // this.mapInstance.add(this.trafficLayer);
        this.mapInstance.addLayer(this.trafficLayer);
        this.mapInstance.removeLayer(this.googleLayer);
        this.mapInstance.removeLayer(this.satellite);
        this.mapInstance.removeLayer(this.roadNet);
      }
      if (type === 'base') {
        //this.mapLayerType = type;

        this.mapInstance.removeLayer(this.googleLayer);
        this.mapInstance.removeLayer(this.satellite);
        this.mapInstance.removeLayer(this.roadNet);
        this.mapInstance.removeLayer(this.trafficLayer);
      }
      this.mapLayerType = type;
      // this.renderAreaPolygons(this.currentAreaNode);

      /*var googleLayer = new AMap.TileLayer.Satellite({
          zooms:[15,24],    //可见级别
          getTileUrl: 'http://mt2.google.cn/vt/lyrs=s@817&hl=zh-CN&gl=cn&x=[x]&y=[y]&z=[z]&s=Galil',
          zIndex:20,
          //tileUrl : 'http://www.google.cn/maps/vt?lyrs=s@817&gl=cn&x=[x]&y=[y]&z=[z]',
          opacity : 1
      });*/
    },

    mapChanged() {
      //console.info('mapChanged',e);
      let that = this;
      //0.5秒防抖
      clearTimeout(window.setMapTimeout);
      window.setMapTimeout = setTimeout(function() {
        // console.info('更新摄像头数据');
        //地图层级变动，清除marker的title信息框
        if (that.infoWindow) {
          that.infoWindow.close();
        }
        that.$emit('amapChanged');
      }, 500);
    },
    /**
     * 地图默认图层样式加载
     */
    mapConfigLoad() {
      // 地图底图加载
      this.lwzxbkMapLayer = new TileLayer({
        //extent: [2423601.4157865737,-2324350.537143386,19812052.859356128,9945726.086343195],
        name: '背景',
        source: new TileWMS({
          url: this.geowebcacheUrl + '/geowebcache/service/wms',
          params: {
            VERSION: '1.1.1',
            LAYERS: 'lwzx_bk',
            FORMAT: 'image/png',
            SRS: 'EPSG:3857',
          },
          tileGrid: new TileGrid({
            //resolution和conf.xml中保持一致
            resolutions: [
              78271.517018034036,
              39135.758509017018,
              19567.879254508509,
              9783.9394949623238,
              4891.9698797730935,
              2445.9849398865467,
              1222.992337651342,
              611.49616882567102,
              305.74808441283551,
              152.87417449834899,
              76.436954957243259,
              38.218609770552874,
              19.109304885276437,
              9.5546524426382184,
              4.7773262213191092,
              2.3886631106595546,
              1.1943315553297773,
              0.59716577766488865,
            ],
            tileSize: [256, 256],
            origin: [-22041258.62706707, 33265069.154240012],
          }),
        }),
      });

      // 路线标记加载

      // 蓝背景加载
      this.lwzxblueMapLayer = new TileLayer({
        //extent: [2423601.4157865737,-2324350.537143386,19812052.859356128,9945726.086343195],
        name: '蓝背景',
        source: new TileWMS({
          url: this.geowebcacheUrl + '/geowebcache/service/wms',
          params: {
            VERSION: '1.1.1',
            LAYERS: 'lwzx_blue',
            FORMAT: 'image/png',
            SRS: 'EPSG:3857',
          },
          tileGrid: new TileGrid({
            //resolution和conf.xml中保持一致
            resolutions: [
              78271.517018034036,
              39135.758509017018,
              19567.879254508509,
              9783.9394949623238,
              4891.9698797730935,
              2445.9849398865467,
              1222.992337651342,
              611.49616882567102,
              305.74808441283551,
              152.87417449834899,
              76.436954957243259,
              38.218609770552874,
              19.109304885276437,
              9.5546524426382184,
              4.7773262213191092,
              2.3886631106595546,
              1.1943315553297773,
              0.59716577766488865,
            ],
            tileSize: [256, 256],
            origin: [-22041258.62706707, 33265069.154240012],
          }),
        }),
      });

      // 高速公路标记加载
      this.lwzxzhujiMapLayer = new TileLayer({
        //extent: [2423601.4157865737,-2324350.537143386,19812052.859356128,9945726.086343195],
        name: '标记',
        source: new TileWMS({
          url: this.geowebcacheUrl + '/geowebcache/service/wms',
          params: {
            VERSION: '1.1.1',
            LAYERS: 'lwzx_zhuji',
            FORMAT: 'image/png',
            SRS: 'EPSG:3857',
          },
          tileGrid: new TileGrid({
            //resolution和conf.xml中保持一致
            resolutions: [
              78271.517018034036,
              39135.758509017018,
              19567.879254508509,
              9783.9394949623238,
              4891.9698797730935,
              2445.9849398865467,
              1222.992337651342,
              611.49616882567102,
              305.74808441283551,
              152.87417449834899,
              76.436954957243259,
              38.218609770552874,
              19.109304885276437,
              9.5546524426382184,
              4.7773262213191092,
              2.3886631106595546,
              1.1943315553297773,
              0.59716577766488865,
            ],
            tileSize: [256, 256],
            origin: [-22041258.62706707, 33265069.154240012],
          }),
        }),
      });

      this.wmsVectorLayer_china = new TileLayer({
        name: '国界面',
        source: new TileWMS({
          url:
            this.geoserverUrl +
            '/geoserver/sf/wms?service=WMS&version=1.1.0&request=GetMap&layers=sf:china_borderline&width=512&height=399&srs=EPSG:3857&format=application/openlayers',
          //projection: 'EPSG:4326',
          params: {
            LAYERS: 'sf:china_borderline',
            VERSION: '1.0.0',
          },
          wrapX: false,
        }),
        zIndex: 10,
      });
    },
    getMarkerIcon(type, status) {
      let that = this,
        iconBase = {};
      if (!status || status === '0') {
        iconBase = that.cameraOfflineImgUrl;
      }
      if (status === '1') {
        iconBase = that.cameraImgUrl;
        // iconBase = that.cameraGifUrl;
      }
      if (status === '2') {
        iconBase = that.cameraErrorImgUrl;
      }

      return new AMap.Icon({
        image: iconBase[type] || iconBase['1'],
        size: new AMap.Size(that.cameraIconWidth, that.cameraIconHeight), // 图标尺寸
        //imageOffset : new AMap.Pixel(0-that.cameraIconWidth/2, 0-that.cameraIconHeight),
        imageSize: new AMap.Size(that.cameraIconWidth, that.cameraIconHeight), // 根据所设置的大小拉伸或压缩图片
        //imageOffset : new AMap.Pixel(0-that.cameraIconWidth/2, 0-that.cameraIconHeight),
        //anchor:'bottom-center', // 设置锚点方位
      });
    },

    pointsSetIn(list) {
      // debugger;
      if (this.camVectorLayer.length) {
        for (var i = 0, lens = this.camVectorLayer.length; i < lens; i++) {
          this.mapInstance.removeLayer(this.camVectorLayer[i]);
        }

        this.camVectorLayer = [];
      }
      // eslint-disable-next-line no-unused-vars
      let createLabelStyle = (feature) => {
        // console.error(feature);
        let iconBase = this.cameraAlarm;
        /* switch (feature.onlineStatus) {
          case '0':
            iconBase = this.cameraOfflineImgUrl;
            break;
          case '1':
            iconBase = this.cameraImgUrl;
            break;
          case '2':
            iconBase = this.cameraErrorImgUrl;
            break;
          default:
            iconBase = this.cameraOfflineImgUrl;
        }
 */
        return new Style({
          image: new Icon({
            anchor: [0, 0], //锚点
            anchorOrigin: 'top-right', //锚点源
            anchorXUnits: 'fraction', //锚点X值单位
            anchorYUnits: 'pixels', //锚点Y值单位
            offsetOrigin: 'center', //偏移原点
            opacity: 1,
            scale: 0.8,
            src: iconBase[feature.type],
          }),
        });
      };
      // console.error(list);
      if (list.length) {
        let num = list.length;
        for (let i = 0, len = num; i < len; i++) {
          let iconFeature = new Feature({
            //坐标点
            geometry: new Point(
              olProj.fromLonLat([list[i].longitude, list[i].latitude])
            ),
            cameraNum: list[i].cameraNum,
            cameraType: list[i].type,
            //名称属性
            /* name: list[i].cameraName,
            khPile: list[i].khPile,
            cameraId: list[i].cameraId,
            
            onlineStatus: list[i].onlineStatus,
            lonlat: [list[i].longitude, list[i].latitude],
            classify: list[i].classify,
            collectFlag: list[i].favoriteFlag,
            zoomlevel: list[i].zoomlevel,
            position: [list[i].long, list[i].lat],fixsxy */
            //自定义数据，作用:唯一标识符
            key: list[i].cameraNum,
            type: 'dragData',
          });
          iconFeature.setStyle(createLabelStyle(list[i]));
          //矢量标注的数据源
          let vectorSource = new VectorSource({
            features: [iconFeature],
          });
          //矢量标注图层
          let vectorLayer = new VectorLayer({
            source: vectorSource,
            zIndex: 200,
          });

          this.mapInstance.addLayer(vectorLayer);
          this.camVectorLayer.push(vectorLayer);
        }
      }
    },

    /**
     * 摄像机加载
     */
    cameraLoad() {
      var _this = this;

      if (this.camVectorLayer.length) {
        for (var i = 0, lens = this.camVectorLayer.length; i < lens; i++) {
          this.mapInstance.removeLayer(this.camVectorLayer[i]);
        }

        this.camVectorLayer = [];
      }

      // 标点渲染
      function show_dian(info) {
        //实例化Vector要素，通过矢量图层添加到地图容器中
        let iconFeature = new Feature({
          //坐标点
          geometry: new Point(
            olProj.fromLonLat([info.longitude, info.latitude])
          ),
          //名称属性
          name: info.cameraName,
          khPile: info.khPile,
          cameraId: info.cameraId,
          cameraNum: info.cameraNum,
          onlineStatus: info.onlineStatus,
          lonlat: [info.longitude, info.latitude],
          //自定义数据，作用:唯一标识符
          key: info.cameraNum,
        });
        iconFeature.setStyle(createLabelStyle(info));
        //矢量标注的数据源
        let vectorSource = new VectorSource({
          features: [iconFeature],
        });
        //矢量标注图层
        let vectorLayer = new VectorLayer({
          source: vectorSource,
          zIndex: 200,
        });
        _this.mapInstance.addLayer(vectorLayer);
        _this.camVectorLayer.push(vectorLayer);
        //实例化Vector要素，通过矢量图层添加到地图容器中 end
      }

      /**
       * 创建标注样式函数,设置image为图标ol.style.Icon
       */
      var createLabelStyle = function(feature) {
        var imgUrl = '';

        switch (feature.onlineStatus) {
          case '0':
            imgUrl = _this.cameraOfflineImgUrl[1];
            break;
          case '1':
            imgUrl = _this.cameraImgUrl[1];
            break;
          case '2':
            imgUrl = _this.cameraErrorImgUrl[1];
            break;
        }

        return new Style({
          image: new Icon({
            anchor: [0, 0], //锚点
            anchorOrigin: 'top-right', //锚点源
            anchorXUnits: 'fraction', //锚点X值单位
            anchorYUnits: 'pixels', //锚点Y值单位
            offsetOrigin: 'center', //偏移原点
            opacity: 1,
            scale: 0.3,
            src: imgUrl,
          }),
          // text: new ol.style.Text({
          //     //位置
          //     textAlign: 'center',
          //     //基准线
          //     textBaseline: 'middle',
          //     //文字样式
          //     font: 'normal 14px 微软雅黑',
          //     //文本内容
          //     text: feature.get('name'),
          //     //文本填充样式（即文字颜色）
          //     fill: new ol.style.Fill({ color: '#aa3300' }),
          //     stroke: new ol.style.Stroke({ color: '#ffcc33', width: 2 })
          // })
        });
      };

      var mapSize = this.mapInstance
        .getView()
        .calculateExtent(this.mapInstance.getSize());
      console.log('就是看电视看', mapSize);
      var firstNode = this.mercatorTolonlat({ x: mapSize[0], y: mapSize[1] });
      var firstNodeLonLat = wgs84_to_gcj02(firstNode.x, firstNode.y);

      var secondNode = this.mercatorTolonlat({ x: mapSize[2], y: mapSize[3] });
      var secondNodeLonLat = wgs84_to_gcj02(secondNode.x, secondNode.y);
      // debugger;
      // 获取摄像机地图列表
      this.$api
        .postCameraListForPot({
          params: {
            zoomLevel: this.mapInstance.getView().getZoom(),
            northEast: secondNodeLonLat.join(','),
            southWest: firstNodeLonLat.join(','),
          },
          data: this.getCamPostBody,
        })
        .then((res) => {
          let lonLat = [];

          // 遍历绚烂设备标点
          for (let i = 0, lens = res.data.length; i < lens; i++) {
            lonLat = gcj02_to_wgs84(
              res.data[i].longitude,
              res.data[i].latitude
            );
            // lonLat = _this.lonLat2Mercator({
            //     lng: lonLat[0],
            //     lat: lonLat[1]
            // });

            res.data[i].longitude = lonLat[0];
            res.data[i].latitude = lonLat[1];

            if (
              _this.filterCamList(
                res.data[i].classify,
                res.data[i].onlineStatus
              )
            ) {
              show_dian(res.data[i]);
            }
          }
        });
    },

    /**
     * 筛选摄像机列表
     */
    filterCamList(type, status) {
      var hasType = this.cameraTypeSelect.indexOf(type);
      var hasStatus = this.cameraStatusChange[parseInt(status)];

      if (hasType !== -1 && hasStatus) {
        return true;
      } else {
        return false;
      }
    },

    /**
     * 添加遮罩
     */
    addConverLayer(provinceId, camInfo = {}, flag = false) {
      var _this = this;

      if (provinceId === '') {
        this.cameraLoad();
        return;
      }

      if (flag) {
        var level = 'province';

        if ((provinceId + '').slice(2, 6) === '0000') {
          level = 'province';
        } else if ((provinceId + '').slice(4, 6) === '00') {
          level = 'city';
        }
        _this.$emit('amapClick', [provinceId, flag.value, level]);
      }

      this.getCamPostBody = camInfo;

      this.actProvinceId = provinceId;

      // 选中全国 地图初始化
      if (provinceId === 100000) {
        this.restoreMap();

        //全国蒙版
        var vectorSourceChina = new VectorSource({
          format: new format.GeoJSON(),
          loader: function(extent, resolution, projection) {
            var proj = projection.getCode();
            var url =
              _this.geoserverUrl +
              '/geoserver/sf/wfs?service=WFS&' +
              'version=1.0.0&request=GetFeature&typename=sf:china_polygon&' +
              'outputFormat=application/json&&srsname=' +
              proj;
            //'&' +
            //'bbox=' +
            //extent.join(',') ;

            var xhr = new XMLHttpRequest();
            xhr.open('GET', url);
            xhr.setRequestHeader('Content-Type', 'application/json');
            var onError = function() {
              vectorSourceChina.removeLoadedExtent(extent);
            };
            xhr.onerror = onError;
            xhr.onload = function() {
              if (xhr.status == 200) {
                //把link更换一下
                vectorSourceChina.addFeatures(
                  vectorSourceChina.getFormat().readFeatures(xhr.responseText)
                );
              } else {
                onError();
              }
            };
            xhr.send();
          },
          strategy: olLoadingstrategy.bbox,
        });

        this.converLayer = new VectorLayer({
          name: 'chinamb',
          id: 'chinamb',
          source: vectorSourceChina,
          // eslint-disable-next-line no-unused-vars
          style: function(feature) {
            var style_line = new Style({
              fill: new Fill({
                color: 'rgba(17,100,237, 0.3)',
              }),
              stroke: new Stroke({
                color: '#08142b',
                width: 1.5,
              }),
            });
            return style_line;
          },
        });

        this.mapInstance.addLayer(this.converLayer);
        return;
      }

      // 地图位置图层切换
      this.getDisplayField(provinceId, function(error, areaNode) {
        var info = areaNode.getBounds();

        var x = '',
          y = '';

        // 获取显示中心点
        x = (info.xc.Q + info.oc.Q) / 2;
        y = (info.xc.R + info.oc.R) / 2;

        // 设置显示中心点/图层层级
        _this.mapInstance.getView().setZoom(areaNode._data.idealZoom);
        _this.mapInstance.getView().setCenter(olProj.fromLonLat([y, x]));
      });

      if (this.converLayer) {
        this.mapInstance.removeLayer(this.converLayer);
      }

      var PropertyName = '';
      var Literal = '';

      if ((provinceId + '').slice(2, 6) === '0000') {
        PropertyName = 'adminsheng';
        Literal = (provinceId + '').slice(0, 2);
      } else if ((provinceId + '').slice(4, 6) === '00') {
        PropertyName = 'adminshi';
        Literal = (provinceId + '').slice(0, 4);
      } else {
        PropertyName = 'adminxian';
        Literal = provinceId + '';
      }

      //全国蒙版
      // eslint-disable-next-line no-redeclare
      var vectorSourceChina = new VectorSource({
        format: new format.GeoJSON(),
        loader: function(extent, resolution, projection) {
          var proj = projection.getCode();
          var url =
            _this.geoserverUrl +
            '/geoserver/sf/wfs?service=WFS&' +
            'version=1.0.0&request=GetFeature&typename=sf:adm_borderline&' +
            `outputFormat=application/json&FILTER=<Filter xmlns="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml">  <PropertyIsEqualTo><PropertyName>${PropertyName}</PropertyName><Literal>${Literal}</Literal></PropertyIsEqualTo> </Filter>&srsname=` +
            proj;
          //'&' +
          //'bbox=' +
          //extent.join(',') ;

          var xhr = new XMLHttpRequest();
          xhr.open('GET', url);
          xhr.setRequestHeader('Content-Type', 'application/json');
          var onError = function() {
            vectorSourceChina.removeLoadedExtent(extent);
          };
          xhr.onerror = onError;
          xhr.onload = function() {
            if (xhr.status == 200) {
              //把link更换一下
              vectorSourceChina.addFeatures(
                vectorSourceChina.getFormat().readFeatures(xhr.responseText)
              );
            } else {
              onError();
            }
          };
          xhr.send();
        },
        strategy: olLoadingstrategy.bbox,
      });

      this.converLayer = new VectorLayer({
        name: 'chinamb',
        id: 'chinamb',
        source: vectorSourceChina,
        // eslint-disable-next-line no-unused-vars
        style: function(feature) {
          var style_line = new Style({
            fill: new Fill({
              color: 'rgba(17,100,237, 0.3)',
            }),
            stroke: new Stroke({
              color: '#08142b',
              width: 1.5,
            }),
          });
          return style_line;
        },
      });

      this.mapInstance.addLayer(this.converLayer);
    },

    /**
     * 关闭播放器
     */
    cameraInfoWindowClose() {
      let that = this;

      /*console.log("cameraInfoWindowClose");
                      // 关闭信息窗体
                      if(that.cameraInfoWindow) that.cameraInfoWindow.close();
                      that.cameraInfoWindow = null;*/

      // 删除选中摄像机图标

      if (this.actCamvectorLayer) {
        this.mapInstance.removeLayer(this.actCamvectorLayer);
        this.actCamvectorLayer = null;
      }

      that.cameraMarkVisable = false;
      that.currentPlayCamera = {};
    },
    /**
     * 根据地址获取经纬度
     * @param {*} lnglat
     */
    getLatByAddress(address) {
      let geocoder = new AMap.Geocoder({
        //city: this.baseTopAdcodes, //城市设为北京，默认：“全国”
        /*  city: this.baseTopAdcodesName */
      });
      let that = this;

      if (this.currentPosMarker) {
        this.mapInstance.removeLayer(this.currentPosMarker);
      }
      geocoder.getLocation(address, function(status, result) {
        if (status === 'complete' && result.geocodes.length) {
          var lnglat = result.geocodes[0].location;
          // document.getElementById('lnglat').value = lnglat;
          that.setLatResult(lnglat + '、' + address);

          let lng = lnglat.lng;
          let lat = lnglat.lat;
          that.addMarker(lng, lat);
        } else {
          console.error('根据地址查询位置失败');
        }
      });
      /* });
      }); */
    },
    addMarker(lng, lat) {
      let that = this;
      let rome = new Feature({
        geometry: new Point(olProj.fromLonLat([lng, lat])),
      });
      rome.setStyle(
        new Style({
          image: new Icon({
            src: that.mark_b,
          }),
          zIndex: 401,
        })
      );
      let vectorSource_point = new VectorSource({
        features: [rome],
      });
      let vectorLayer_point = new VectorLayer({
        name: '自定义打点图层',
        source: vectorSource_point,
        zIndex: 402,
      });
      that.mapInstance.addLayer(vectorLayer_point);
      that.currentPosMarker = vectorLayer_point;
    },
    /**
     * 根据经纬度获取地址
     * @param {*} areaNode
     */
    getAddressByLat(lnglat) {
      let geocoder = new AMap.Geocoder({
        // city: this.baseTopAdcodes, //城市设为北京，默认：“全国”
        radius: 1000, //范围，默认：500
      });
      let that = this;
      let lng = lnglat[0];
      let lat = lnglat[1];
      if (this.currentPosMarker) {
        this.mapInstance.removeLayer(this.currentPosMarker);
      }
      that.addMarker(lng, lat);

      geocoder.getAddress(lnglat, function(status, result) {
        if (status === 'complete' && result.regeocode) {
          var address = result.regeocode.formattedAddress;
          // console.error('address', address);
          that.setLatResult(lnglat + '、' + address);
          /* document.getElementById('address').value = address; */
        } else {
          console.error('根据经纬度查询地址失败');
        }
      });
    },
    removeCurrentPosMarker() {
      if (this.currentPosMarker) {
        this.mapInstance.removeLayer(this.currentPosMarker);
      }
    },
    /**
     * 画路线
     */
    drawLine(line_arr, center) {
      /* let polyline = new AMap.Polyline({
        path: lineArr,
        strokeColor: 'red',
        strokeOpacity: 1,
        strokeWeight: 6,
        strokeStyle: 'solid',
        strokeDasharray: [10, 5],
        geodesic: true
      });
      if (this.currPolyline) {
        this.mapInstance.removeLayer(this.currPolyline);
      }
      this.currPolyline = polyline;
      polyline.setMap(this.mapInstance);
      this.mapInstance.setCenter(center);
      this.mapInstance.setZoom(13); */
      if (this.currPolyline) {
        this.mapInstance.removeLayer(this.currPolyline);
      }
      let multiLineArr = [];
      let line = [];
      let centerPoint = olProj.fromLonLat(center);
      for (var i = 0; i < line_arr.length; i++) {
        let coord = olProj.fromLonLat(line_arr[i]);
        line.push(coord);
      }
      multiLineArr.push(line);
      var feature = new Feature({
        id: 'roadLines',
        geometry: new MultiLineString(multiLineArr),
      });

      feature.setStyle(
        new Style({
          stroke: new Stroke({
            width: 5,
            color: 'orange', //#1C86EE
          }),
          zIndex: 2,
        })
      );
      var vectorSource_line = new VectorSource({
        features: [feature],
      });

      var vectorLayer_line = new VectorLayer({
        name: '自定义画线图层',
        source: vectorSource_line,
        zIndex: 1,
      });
      this.currPolyline = vectorLayer_line;
      this.mapInstance.addLayer(vectorLayer_line);
      this.mapInstance.getView().setCenter(centerPoint);
      this.mapInstance.getView().setZoom(12);
    },
    /**
     * 打开播放器
     * @param record
     */
    cameraInfoWindowShow(record, pixel) {
      let that = this;

      if (record.data.cameraName) {
        record.data.name = record.data.cameraName;
      }

      if (record && record.data && record.data.name) {
        //that.currentPlayCamera = record.data;
        console.info('cameraInfoWindowShow ', record, pixel);

        // 传入经纬度，设置地图中心点
        // 标准写法
        // 简写 var position = [116, 39];
        let position = olProj.fromLonLat(record.data.position);
        this.mapInstance.getView().setCenter(position);

        //设置显示层级，判断当前点位是否在范围内，否则需要调整层级
        let currZoom = this.mapInstance.getView().getZoom(), //当前层级
          newZoom = 18,
          myZoom = parseInt(record.data.zoomlevel) || 0;
        //层级大于当前层级，调整到指定层级
        if (myZoom) {
          newZoom = myZoom; // 存在特定层级
          if (myZoom <= currZoom) {
            newZoom = currZoom; // 当前层级已经显示摄像头，不做调整
          }
        }
        this.mapInstance.getView().setZoom(newZoom);

        //marker.setIcon('//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png')

        //this.mapInstanceInstance.setZoomAndCenter(18, [record.data.position[0], record.data.position[1]]);

        that.cameraMarkVisable = true;

        //鼠标位置
        if (pixel && pixel[0] && pixel[1]) {
          // that.$refs.amapPlayer.setWindowSite(pixel[0], pixel[1]);
        }
        setTimeout(() => {
          that.$refs.amapPlayer.getVideoUrlToPlay(record.data);
        }, 1000);
      }
    },

    /**
     * 获取区域code
     * @param  coodinate  区域经纬度 墨卡托坐标
     * @return Object     省份编码与名称
     */
    getAreaCode(coodinate) {
      let _this = this;
      let wgS84 = _this.mercatorTolonlat({ x: coodinate[0], y: coodinate[1] });
      let gcj02 = _this.$gcoord.transform(
        [wgS84.x, wgS84.y],
        _this.$gcoord.WGS84,
        _this.$gcoord.GCJ02
      );

      return _this.$axios.get(
        _this.geoserverUrl +
          `/API/getInverseGeocoding.do?lat=${gcj02[1]}&lng=${gcj02[0]}`
      );
    },

    /**
     * 添加选中
     * @param info 当前选中摄像机信息
     */
    addActCameraIcon(info) {
      var _this = this;

      if (this.actCamvectorLayer) {
        this.mapInstance.removeLayer(this.actCamvectorLayer);
        this.actCamvectorLayer = null;
      }

      //矢量标注的数据源
      var vectorSource = new VectorSource();
      //矢量标注图层
      this.actCamvectorLayer = new VectorLayer({
        source: vectorSource,
        zIndex: 999,
      });
      this.mapInstance.addLayer(this.actCamvectorLayer);

      //矢量标注样式设置函数，设置image为图标ol.style.Icon
      // eslint-disable-next-line no-unused-vars
      function createLabelStyle(feature) {
        return new Style({
          image: new Icon({
            anchor: [0, 0], //锚点
            anchorOrigin: 'top-right', //锚点源
            anchorXUnits: 'fraction', //锚点X值单位
            anchorYUnits: 'pixels', //锚点Y值单位
            offsetOrigin: 'center', //偏移原点
            opacity: 1,
            scale: 0.7,
            src: _this.cameraGifUrl['1'], //图标的URL
          }),
        });
      }

      //新建一个要素ol.Feature
      var newFeature = new Feature({
        geometry: new Point(olProj.fromLonLat(info.lonlat)), //几何信息
        name: '',
      });
      newFeature.setStyle(createLabelStyle(newFeature)); //设置要素样式
      vectorSource.addFeature(newFeature);
    },

    /**
     * web墨卡托(3857)转经纬度坐标(4326)
     * @param mercator
     * @returns {{x: number, y: number}}
     */
    mercatorTolonlat(mercator) {
      var lonlat = { x: 0, y: 0 };
      var x = (mercator.x / 20037508.34) * 180;
      var y = (mercator.y / 20037508.34) * 180;
      y =
        (180 / Math.PI) *
        (2 * Math.atan(Math.exp((y * Math.PI) / 180)) - Math.PI / 2);
      lonlat.x = x;
      lonlat.y = y;
      return lonlat;
    },

    /**
     * 经纬度坐标(4326)转web墨卡托(3857)
     * @param lonlat
     * @returns {{x: number, y: number}}
     */
    lonLat2Mercator(lonlat) {
      var mercator = {
        x: 0,
        y: 0,
      };
      var earthRad = 6378137.0;
      mercator.x = ((lonlat.lng * Math.PI) / 180) * earthRad;
      var a = (lonlat.lat * Math.PI) / 180;
      mercator.y =
        (earthRad / 2) * Math.log((1.0 + Math.sin(a)) / (1.0 - Math.sin(a)));
      return mercator;
    },

    /**
     * 高德地图加载
     */
    gdMapLoad() {
      var _this = this;

      this.gdMap = new AMap.Map('gdMap', {
        cursor: 'default',
        resizeEnable: true,
        animateEnable: true,
        disableSocket: true,
        autoSetFitView: false,
        //viewMode: '3D',
        // showLabel: false,//不显示地图文字标记
        pitch: 0,
        zoom: 5,
        //mapStyle: 'amap://styles/1ca5820aeb977f88e1b07925d4ff1d4c',
        mapStyle: 'amap://styles/b7f705be4f2be54091ca786949404f82',
      });

      AMapUI.load(['ui/geo/DistrictExplorer', 'lib/$'], function(
        DistrictExplorer
      ) {
        //创建一个实例
        let distExp = new DistrictExplorer({
          // eventSupport: true, //打开事件支持
          map: _this.gdMap,
          preload: [100000],
          zIndex: 200,
        });

        _this.districtExplorer = distExp;
      });
    },

    /**
     * 获取地图显示范围
     */
    getDisplayField(adcode, callback) {
      var _this = this;

      this.districtExplorer.loadAreaNode(adcode, function(error, areaNode) {
        //console.info('areaNode  ',adcode,areaNode);
        _this.districtExplorer.clearFeaturePolygons();
        if (error) {
          if (callback) {
            callback(error);
          }
          //console.error('loadAreaNode error ', error);
          return;
        }

        if (callback) {
          callback(null, areaNode);
        }
      });
    },

    /**
     * 恢复地图初始状态
     */
    restoreMap() {
      if (this.converLayer) {
        this.mapInstance.removeLayer(this.converLayer);
      }

      // 设置显示中心点/图层层级
      this.mapInstance.getView().setZoom(this.initZoom);
      this.mapInstance.getView().setCenter(this.initCenter);
    },

    /**
     * 全局事件绑定
     */
    globalEventBind() {
      this.$root.$off('camWinClose');
      this.$root.$off('removeEventMarker');
      /*   this.$root.$off('editEventMarkerLonlat');
      this.$root.$off('mapPan'); */
      /**
       * 摄像机弹窗关闭
       */
      this.$root.$on('camWinClose', () => {
        this.cameraInfoWindowClose();
      });
      this.$root.$on('removeEventMarker', () => {
        if (this.backMark) {
          this.mapInstance.removeLayer(this.backMark);
        }
        this.cameraInfoWindowClose();
        if (this.$refs.dragMarkerGhost) {
          this.$refs.dragMarkerGhost.$el.style.top = '-50px';
          this.$refs.dragMarkerGhost.$el.style.left = '-50px';
        }
      });
    },

    dragMarkerGhostDragstart() {
      // console.error('dragMarkerGhostDragstart', e);
    },
    dragMarkerGhostDragend() {
      // console.e('dragMarkerGhostDragend', e);
      //this.$root.$emit("removeEventMarker", "");
    },
    dragHandle(obj) {
      this.setDragMove(obj);
    },
    /**
     * 销毁地图
     */
    destroyMap() {
   
      this.mapInstance.dispose();
    },
  },
  destroyed(){
    console.log('地图销毁2') 
    this.destroyMap();
  },
  beforeMount() {
    this.globalEventBind();
  },
  mounted() {
    this.$nextTick(() => {
      this.mapConfigLoad();
      this.mapLoad();
      this.addConverLayer(100000);
      // this.cameraLoad();
      this.gdMapLoad();
    });
    this.container = document.getElementById('popup');
    this.popupContent = document.getElementById('popup-content');
  },
};
</script>

<style lang="less">
.qf-map_wrap {
  height: 100%;
  width: 100%;
  // background-image: url('../assets/images/traffic_map/map_bg.png');
  background-repeat: repeat;
  background-size: auto 120%;
  background-position: center;
  position: relative;

  > .qf-map-box {
    height: 100%;
    width: 100%;
  }

  .ol-popup {
    font-size: 14px;
    line-height: 27px;
    color: hsla(0, 0%, 100%, 0.8);
    padding: 0 10px;
    background: #2261b1;
    border: 1px solid #3aa8f3;
    box-shadow: inset 0 0 4px 0 #3aa8f3;

    .popup-content {
      background: #fff;
      padding: 3px;
    }
    .ol-popup-closer {
      position: absolute;
      top: 2px;
      right: 8px;
    }
    .ol-popup-closer:after {
      content: 'x';
      color: #fff;
    }
  }

  .ol-zoom.ol-unselectable.ol-control {
    display: none;
  }
  > .qf-map-box {
    height: 100%;
    width: 100%;
  }
  .ol-zoom.ol-unselectable.ol-control,
  .ol-rotate.ol-unselectable.ol-control {
    display: none;
  }
}
</style>
