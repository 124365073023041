<template>
  <div style="width:96%;">
    <div class="hd flex justify-between items-center text-lightblue m-b-sm">
      <span class="sp-item relative p-w-sm">{{ text }}</span>
      <template>
        <a-icon
          type="caret-down"
          class="pointer"
          v-show="type === 'down'"
          @click="toggleCon()"
        />
        <a-icon
          type="caret-up"
          class="pointer"
          v-show="type === 'up'"
          @click="toggleCon()"
        />
      </template>
    </div>
    <!-- 子菜单里面的 -->
    <div class="con" v-show="type === 'down' ? false : true">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventGroupItem',
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'down',
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    toggleCon() {
      this.$emit('toggleConHandle', this.type, this.index);
    },
  },
};
</script>

<style scoped>
.sp-item {
  padding-left: 15px;
}
/* 文字前面的符号 */
.sp-item::before {
  content: '';
  position: absolute;
  left: 2px;
  top: 8px;
  width: 7px;
  height: 7px;
  background: #364b6e;
  transform: rotate(135deg);
}
</style>
