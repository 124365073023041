<template>
  <div class="full-width full-height">
    <div class="f-hd flex items-center justify-between m-b-sm">
      <div class="flex">
        <title-com text="今日事件" />
        <span class="text-lightblue m-l-xs" v-show="hasPermission(10921212)">{{ total }}</span>
      </div>
      <i class="icon-all pointer" @click="goViewAll"></i>
    </div>
    <vue-scroll :ops="$root.scrollOpsY" class="scroll-ht">
      <template>
        <auth-mode v-if="!hasPermission(10921212)"></auth-mode>
        <template v-else>
          <div
            class="traffic-item flex p-t-sm"
            :class="{ 'm-b-sm': index === 0 }"
            v-for="(item, index) in todayData"
            :key="index"
          >
            <div
              class="ti wd20  text-center pointer"
              v-for="(child, ci) in item"
              :key="ci"
              @click="goView(child)"
            >
              <a-badge
                :count="child.count"
                :number-style="{ backgroundColor: '#14A4FF' }"
              >
                <img :src="imgSrc[child.eventKey]" class="img-width" />
              </a-badge>
              <div class="p-w-sm">
                <span
                  class="block text-lightblue m-t-xs line20 ellipsis"
                  :title="child.eventName"
                  v-html="child.eventName"
                ></span>
              </div>
            </div>
          </div>
        </template>
      </template>
    </vue-scroll>
  </div>
</template>

<script>
import TitleCom from './TitleCom';
import { group } from '@/tools';
import { CODE_OK } from '@/request/config_code';
import AuthMode from '@/components/AuthMode';
export default {
  name: 'BotLeft',
  data() {
    return {
      todayData: [
        /*  */
      ],
      imgSrc: {
        meteorological: require('@/assets/AI/traffic-meto.png'),
        traffic: require('@/assets/AI/traffic-accident.png'),
        others: require('@/assets/AI/traffic-other.png'),
        car_flow: require('@/assets/AI/traffic-block.png'),
        construction: require('@/assets/AI/traffic-construction.png'),
        flow: require('@/assets/AI/traffic-control.png'),
      },
      total: 0,
    };
  },
  components: {
    TitleCom,
    AuthMode
  },
  computed: {},
  watch: {},
  created() {
    this._getTodayInfo();
  },
  methods: {
    _getTodayInfo() {
      this.$api.aiCenterY.todayEventStatistics().then((res) => {
        if (res.code === CODE_OK) {
          // console.error('haha', res.data);
          let list = res.data.data.map((item, index) => {
            item.order = index;
            item.eventName = `${item.eventName.slice(
              0,
              4
            )}<br/>${item.eventName.slice(4)}`;
            return item;
          });
          this.todayData = group(list, 4);
          this.total = res.data.total;
        }
      });
    },
    goViewAll() {
      if(!this.hasPermission(10921212)) {
        this.permissionWarning();
        return;
      }
      this.$router.push({ path: '/ai-center/event-all?time=false' });
    },
    goView(child) {
      this.$router.push({
        path: `/ai-center/event-all?time=false&parentEventId=${child.parentEventId}`,
      });
    },
  },
};
</script>

<style scoped>
.wd20 {
  width: 25%;
}
.line20 {
  line-height: 20px;
}
.scroll-ht {
  height: calc(100% - 40px) !important;
}
.img-width {
  width: 69px;
}
</style>
