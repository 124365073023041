<template>
  <div class="full-width full-height p-sm">
    <top-right-analyze />
    <top-right-analyzed />
  </div>
</template>

<script>
import TopRightAnalyze from './TopRightAnalyze';
import TopRightAnalyzed from './TopRightAnalyzed';
export default {
  name: 'TopRight',
  data() {
    return {};
  },
  components: {
    TopRightAnalyze,
    TopRightAnalyzed,
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.f-bg {
  background: linear-gradient(
    to left,
    rgba(15, 40, 61, 0.3),
    rgba(46, 86, 142, 0.3)
  );
  height: 164px;
  padding: 20px 0 0 20px;
}
</style>
