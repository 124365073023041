<template>
  <div>
    <a-modal
      title="报警设置"
      width="33%"
      :visible="visible"
      @cancel="handleCancel"
      :footer="null"
    >
      <div style="width:101%;">
        <vue-scroll :ops="$root.scrollOpsY" style="height:350px;">
          <event-group-item
            :text="item.text"
            :type="item.show ? 'up' : 'down'"
            :index="index"
            v-for="(item, index) in datInfo"
            :key="item.parentId"
            @toggleConHandle="toggleHandle"
          >
            <template>
              <div
                class="flex items-center f12 m-b-sm"
                v-for="(child, idx) in item.info"
                :key="child.id"
              >
                <div class="wd100 f12">
                  <!-- v-model相当于checked -->
                  <a-switch
                    v-model="child.childValue"
                    @change="(value) => onChange(value, index, idx)"
                  />{{ child.childText }}
                </div>
                <div class="flex-one f12">
                  <div v-if="child.childShow === 1">
                    <a-input
                      v-model="child.input"
                      style="width:120px;"
                      placeholder="请输入阈值"
                      class="m-r-xs f12"
                    ></a-input
                    >km
                  </div>
                  <a-radio-group
                    :disabled="disabled"  
                    v-model="child.input"
                    :options="child.plainOptions"
                    @change="(e) => onChangeRadio(e, index, idx)"
                  />
                </div>
              </div>
            </template>
          </event-group-item>
        </vue-scroll>
        <div class="flex items-center justify-center full-width">
          <a-button type="primary" @click="btnSure">确定</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import EventGroupItem from '@/components/EventGroupItem';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  name: 'EventAlarmDialog',
  data() {
    return {
      disabled:true,
      datInfo: [
        {
          parentId: 0,
          type: 0,
          text: '交通拥堵',
          show: false,
          info: [
            {
              id: 0,
              childType: 0,
              // false为关闭状态
              childValue: false,
              childText: '一般拥堵',
              input: '',
              childShow: 1,
            },
            {
              id: 1,
              childType: 1,
              childValue: false,
              childText: '严重拥堵',
              input: '',
              childShow: 1,
            },
            {
              id: 2,
              childType: 2,
              childValue: false,
              childText: '特严重拥堵',
              input: '',
              childShow: 1,
            },
          ],
        },
        {
          parentId: 1,
          type: 1,
          text: '交通事故',
          show: false,
          info: [
            {
              id: 0,
              childType: 0,
              childValue: false,
              childText: '多车事故',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
            {
              id: 1,
              childType: 1,
              childValue: false,
              childText: '单车事故',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
            {
              id: 2,
              childType: 2,
              childValue: false,
              childText: '危化品',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
          ],
        },
        {
          parentId: 2,
          type: 2,
          text: '地质灾害',
          show: false,
          info: [
            {
              id: 0,
              childType: 0,
              childValue: false,
              childText: '塌方',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
          ],
        },
        {
          parentId: 3,
          type: 3,
          text: '气象灾害',
          show: false,
          info: [
            {
              id: 0,
              childType: 0,
              childValue: false,
              childText: '降雪',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
            {
              id: 1,
              childType: 1,
              childValue: false,
              childText: '雾/霾',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
          ],
        },
        {
          parentId: 4,
          type: 4,
          text: '交通违法',
          show: false,
          info: [
            {
              id: 0,
              childType: 0,
              childValue: false,
              childText: '停车',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
            {
              id: 1,
              childType: 1,
              childValue: false,
              childText: '逆行',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
            {
              id: 2,
              childType: 2,
              childValue: false,
              childText: '行人',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
            {
              id: 3,
              childType: 3,
              childValue: false,
              childText: '行人穿越',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
            {
              id: 4,
              childType: 4,
              childValue: false,
              childText: '抛洒物',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
            {
              id: 5,
              childType: 5,
              childValue: false,
              childText: '非机动车',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
            {
              id: 6,
              childType: 6,
              childValue: false,
              childText: '机动车驶离',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
            {
              id: 7,
              childType: 7,
              childValue: false,
              childText: '应急车道占用',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
          ],
        },
        {
          parentId: 5,
          type: 5,
          text: '施工养护',
          show: false,
          info: [
            {
              id: 0,
              childType: 0,
              childValue: false,
              childText: '施工养护',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
          ],
        },
        {
          parentId: 6,
          type: 6,
          text: '交通管制',
          show: false,
          info: [
            {
              id: 0,
              childType: 0,
              childValue: false,
              childText: '单向施工',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
            {
              id: 1,
              childType: 1,
              childValue: false,
              childText: '双向施工',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
          ],
        },
        {
          parentId: 7,
          type: 7,
          text: '其它',
          show: false,
          info: [
            {
              id: 0,
              childType: 0,
              childValue: false,
              childText: '烟火',
              input: 0,
              plainOptions: [
                { label: '一般事件', value: 0 },
                { label: '严重事件', value: 1 },
                { label: '特严重事件', value: 2 },
              ],
            },
          ],
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    btnSure() {
      this.$emit('close');
    },
    handleCancel() {
      this.$emit('close');
    },
    onChange(e, index, idx) {
      // console.error(e, index, idx);//true  2(大的按钮索引号)  0(子的所有按钮号)
      this.disabled=!this.disabled
      this.$set(this.datInfo[index]['info'][idx], 'childValue', e);
    },
    onChangeRadio(e, index, idx) {
      // console.error(e.target.value, index, idx);
      this.$set(this.datInfo[index]['info'][idx], 'input', e.target.value);
    },
    toggleHandle(type, index) {
      let typeBool = type === 'down' ? true : false;
      // this.datInfo[index] 是要更改的数据源  
      // 'show'是要更改的具体数据
      //typeBool 重新赋的值
      this.$set(this.datInfo[index], 'show', typeBool);
    },
  },
  components: {
    EventGroupItem,
  },
};
</script>

<style scoped>  
.ant-switch{
  color:rgba(0,0,0,0.65);
  background-color:rgba(191,191,191,1);
}
.ant-switch-checked{
  background-color:#1890ff ;
}
.wd100 {
  width: 130px;
}
</style>
