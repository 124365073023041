<template>
  <div class="full-width ht-style">
    <div class="event-alarm full-height">
      <div class="flex m-b-sm">
        <title-com text="事件报警" />
        <i
          class="icon-alarm1 icon-a-set pointer"
          v-show="false"
          @click="openGroupHandle()"
        ></i>
      </div>

      <vue-scroll :ops="$root.scrollOpsY" class="scroll-ht">
        <div class="alarm-content  f12">
          <template>
            <div class="text-center text-lightblue f16" v-if="!hasPermission(10921210)">你没有权限访问该模块！</div>
            <template v-else>
              <div
                class="alarm-item flex items-center m-b-sm  p-xs m-b-xs pointer"
                v-for="(item, index) in alarmList"
                :key="index.id"
                @click="viewDetail(item)"
              >
                <div class="alarm-icon-con flex items-center">
                  <!-- <i class="icon-alarm icon-a-hot m-r-xs"></i> -->
                  <i class="icon-alarm  m-r-xs" :class="getClsType(item)"></i>
                  <span class="text-lightblue  flex-one">{{ getTime(item) }}</span>
                </div>
                <div class="flex-one cm  text-lightblue">
                  {{ getDetail(item) }}
                </div>
                <!-- <div class="alarm-right flex items-center justify-center">
                  <i
                    class="icon-a-r"
                    :class="{
                      'icon-a-bridge': item.sfsq == 1,
                      'icon-a-s': item.sfss == 0,
                    }"
                  ></i>
                </div> -->
              </div>
            </template>
          </template>
        </div>
      </vue-scroll>
    </div>
    <event-alarm-dialog :visible="eventVisible" @close="closeHandle" />
    <event-alarm-detail
      ref="eventAlarm"
      :visible="eventDetVisible"
      :cameraDetailId="cameraDetailId"
      :cameraDetail="cameraDetail"
      @close="closeDetail"
      @misReportHandle="misReportParentHandle"
    />
  </div>
</template>
<script>
import TitleCom from './TitleCom';
import EventAlarmDialog from './EventAlarmDialog';
import EventAlarmDetail from '@/components/EventAlarmDetail';
import { CODE_OK } from '@/request/config_code';
import moment from 'moment';
import { mapActions, mapState, mapMutations } from 'vuex';
export default {
  components: { TitleCom, EventAlarmDialog, EventAlarmDetail },
  data() {
    return {
      eventVisible: false,
      eventDetVisible: false,
      cameraDetailId: '',
      cameraDetail: {},
      // alarmList: [],
    };
  },
  created() {
    this.getAlarmList();
  },
  mounted() {
    window.addEventListener('onmessageWS', this.getsocketData);
  },
  computed: {
    /*  alarms() {
      return this.alarmList.map((item) => {
        this.$set(item);
        return item;
      });
    }, */
    styleObj() {
      return `${this.$g.isDepart ? 'height:50%' : 'height:100%'}`;
    },
    ...mapState({
      alarmList: (state) => state.aiCenter.alarmList,
    }),
  },
  methods: {
    ...mapMutations(['setAlarmList']),
    ...mapActions(['getAlarmList']),
    getClsType(item) {
      let car = [
        'vehi_stop',
        'vehi_slow_pass',
        'vehi_converse',
        'vehi_reverse',
        'vehi_accident',
        'into_forbidden_area',
        'abandon',
        'tunnel_power_outage',
        'into_drivingarea',
        'carry_people',
        'without_helmet',
        'nmve_converse',
        'vehi_mutil_slow',
        'pass_vehi',
        'vehi_mutil_slow_clear',
        'vehi_day_congestion',
        'vehi_night_congestion',
        'vehi_day_congestion_clear',
        'vehi_night_congestion_clear',
        'construction_area',
        'camera_move',
        'event_check',
      ];
      let weather = ['wind', 'rain', 'fog', 'snow', 'smoke'];
      if (car.includes(item.eventKey)) {
        return 'icon-a-car';
      }
      if (weather.includes(item.eventKey)) {
        return 'icon-a-hot';
      }
    },
    getsocketData(e) {
      // 创建接收消息函数
      const data = e && JSON.parse(e.detail.data);
      //'G50沪渝高速渝长下行录制时长已超过30分钟，请及时处理！'
      if (data.type == 1) {
        let alarmList = this.alarmList;
        if (alarmList.length > 100) {
          alarmList = alarmList.slice(80, 100);
        }
        alarmList.push(data.data);
        this.setAlarmList(alarmList);
      }
    },
    _getAlarmList() {
      this.$api.aiCenterY.queryNewestList().then((res) => {
        if (res.code === CODE_OK) {
          this.alarmList = res.data;
        }
      });
    },
    getTime(item) {
      return moment(item.startTime).format('HH:mm');
    },
    getDetail(item) {
      return `${item.roadCode}${item.roadName}${item.pileNum}发生${item.eventName}事件`;
    },
    closeGroup() {
      this.eventVisible = false;
    },
    openGroupHandle() {
      this.eventVisible = true;
    },
    closeHandle() {
      this.eventVisible = false;
    },
    closeDetail() {
      this.eventDetVisible = false;
      this.cameraDetailId = '';
      this.cameraDetail = {};
    },
    misReportParentHandle() {
      this.getAlarmList();
    },
    viewDetail(record) {
      this.eventDetVisible = true;
      this.cameraDetailId = record.hisId;
    },
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.resizeWindow.bind(this));
    // 在需要的时候卸载监听事件，比如离开页面
    window.removeEventListener('onmessageWS', this.getsocketData);
  },
};
</script>

<style scoped>
.alarm-item {
  background: linear-gradient(
    90deg,
    rgba(25, 45, 80, 0.5),
    rgba(46, 86, 142, 0.5)
  );
  border-radius: 25px;
}
.alarm-icon-con {
  background: rgba(22, 51, 95, 0.7);
  width: 76px;
  border-radius: 16px;
}
.icon-alarm {
  width: 30px;
  height: 30px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.icon-alarm1 {
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/* 大雾 */
.icon-a-hot {
  background-image: url('../../../assets/AI/icon-alarm-hot.png');
}
/* 坍塌 */
.icon-a-fall {
  background-image: url('../../../assets/AI/icon-alarm-fall.png');
}
/* 事故 */
.icon-a-car {
  background-image: url('../../../assets/AI/icon-alarm-car.png');
}
.icon-a-set {
  background-image: url('../../../assets/AI/AI-left.png');
}
.alarm-right {
  width: 40px;
}
.icon-a-r {
  width: 22px;
  height: 22px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
/* 隧道 */
.icon-a-bridge {
  background-image: url('../../../assets/AI/left-resource1.png');
}
/* 桥梁 */
.icon-a-s {
  background-image: url('../../../assets/AI/left-resource2.png');
}
.ht-style {
  height: 50%;
}
.scroll-ht {
  height: calc(100% - 30px) !important;
}
</style>
