<template>
  <div>
    <a-modal
      :visible="visible"
      :footer="null"
      :dialogStyle="dialogStyle"
      :centered="true"
      :destroyOnClose="true"
      width="60%"
      @cancel="handleCancel"
    >
      <span slot="title" class="text-white">
        {{ title }}
      </span>
      <div>
        <div class="flex m-b-sm">
          <!-- v-model当前时间  format展示时间的形式  defaultValue默认时间默认开始的时分秒为00:00:00 结束时间23:59:59  -->
          <a-range-picker
            v-model="dateRange"
            style="width:400px;"
            format="YYYY-MM-DD HH:mm:ss"
            class="m-r-sm"
            :showTime="{
              defaultValue: [defaultStart, defaultEnd],
            }"
            @change="changeDataRange"
          />
          <a-button
            type="primary"
            class="btn-primary m-r-sm"
            @click="searchHandle"
            >搜索</a-button
          >
          <a-button class="btn-reset" @click="resetHandle">重置</a-button>
        </div>
        <!-- 表格 -->
        <div>
          <a-table
            size="small"
            :components="resibleTableHeader"
            :bordered="true"
            :columns="columns"
            :data-source="data"
            :pagination="paginationOpt"
            :scroll="scroll"
            :loading="loading"
            :rowKey="(record, index) => record.id"
          >
            <!-- record是点击表格的那一行 -->

            <span
              slot="operation"
              class="pointer"
              slot-scope="text, record"
              @click="viewDetail(record)"
            >
              查看详情
            </span>
          </a-table>
        </div>
      </div>
    </a-modal>
    <!-- <event-alarm-detail :visible="visibleDetail" @close="closeDetail" /> -->
    <event-alarm-detail
      ref="eventAlarm"
      :visible="visibleDetail"
      :cameraDetailId="cameraDetailId"
      @close="closeDetail"
      @misReportHandle="misReportParentHandle"
    />
  </div>
</template>

<script>
import EventAlarmDetail from '@/components/EventAlarmDetail';
import ResizableTable from '@/components/mixins/resizeableTable';
import moment from 'moment';
export default {
  name: 'TopLeftTableDialog',
  mixins:[ResizableTable],
  props: {
    visible: {
      type: Boolean,
    },

    searchParams: {
      type: Object,
      default() {
        return {};
      },
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      data: [],
      columns: [
        {
          title: '序号',
          dataIndex:'eventIndex',
          customRender: (text, record, index) => index + 1,
          width: 60,
        },
        {
          title: '报警内容',
          dataIndex: 'eventName',
          width: 200,
          customRender: (text, record) => {
            return `${record.parentEventName}-${text}`;
          },
        },

        {
          title: '桩号',
          dataIndex: 'mileageNo',
          width: 100,
        },
        {
          title: '报警上报时间',
          dataIndex: 'createdTime',
          width: 160,
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: 100,
          className:'column-center'
        },
      ],
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true, //是否可以改变pageSize
        showQuickJumper: true, //是否可以快速跳转至某页
        pageSizeOptions: ['5', '10', '15', '20'], //指定每页显示多少条
        showTotal: (total) => `共 ${total} 条`, // 	用于显示数据总量和当前数据顺序
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this._getDetail();
          // this.searchCameraFromTest();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this._getDetail();
          // this.searchCameraFromTest();
        },
      },
      visibleDetail: false,
      dateRange: null,
      cameraDetailId: '',
      cameraDetail: {},
      scroll: {
        y: (window.innerHeight > 768 ? 640 : window.innerHeight) - 360,
        x:'100%'
      },
      dialogStyle: { maxWidth: '1000px', maxHeight: '550px', height: '80%' },
      loading: false,
    };
  },
  computed: {
    defaultStart() {
      return moment('00:00:00', 'HH:mm:ss');
    },
    defaultEnd() {
      return moment('23:59:59', 'HH:mm:ss');
    },
  },
  components: {
    EventAlarmDetail,
  },
  watch: {
    /* id() {
      this._getDetail();
    }, */
    searchParams: {
      handler() {
        this.searchHandle();
      },
      deep: true,
    },
  },
  methods: {
    _getDetail() {
      let data = {
        currPage: this.paginationOpt.current,
        eventType: this.searchParams.eventType,
        pageSize: this.paginationOpt.defaultPageSize,
        roadId: this.searchParams.roadId,
        startTime: this.dateRange
          ? moment(this.dateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          : '',
        endTime: this.dateRange
          ? moment(this.dateRange[1]).format('YYYY-MM-DD HH:mm:ss')
          : '',
      };
      this.loading = true;
      this.$api.aiCenterY.getEventStatisticsInfo(data).then((res) => {
        if (res.code === 200) {
          this.data = res.data;
          // console.log('222',this.data);
          this.loading = false;
          this.paginationOpt.total = res.total;
        }
      });
    },
    changeDataRange(datePicker) {
      /* this.dateRange = [
        moment(`${moment(datePicker[0]).format('YYYY-MM-DD')} 00:00:00`),
        moment(`${moment(datePicker[0]).format('YYYY-MM-DD')} 23:59:59`),
      ]; */
      this.dateRange = datePicker;
      // console.log(this.dateRange);
    },
    searchHandle() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this._getDetail();
    },
    resetHandle() {
      this.dateRange = null;
      this.searchHandle();
    },
    handleCancel() {
      this.resetHandle();
      this.$emit('close');
    },
    viewDetail(record) {
      // console.error(record);
      if(!this.hasPermission(1092121110)) {
        this.permissionWarning();
        return;
      }
      this.visibleDetail = true;
      this.cameraDetailId = record.id;

      // console.log('哈哈', this.cameraDetail);
    },
    closeDetail() {
      this.visibleDetail = false;
      this.cameraDetailId = '';
    },
    misReportParentHandle() {
      /* this.visibleDetail = false; */
      this.searchHandle();
      this.$emit('misReportParent');
    },
  },
};
</script>

<style scoped>
::v-deep .ant-modal-content {
  height: 100%;
}
</style>
