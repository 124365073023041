<template>
  <div  class="text-center text-lightblue f16">
      你没有权限访问该模块!
  </div>
</template>

<script>
export default {
    name:'AuthMode'
}
</script>

<style>

</style>