<template>
  <div class="full-width full-height">
    <!--  <vue-scroll :ops="$root.scrollOpsY" style="height: 100%">
      <div class="event-alarm">
        <div class="e-hd">
          <span class="p-w-md relative text-white before-line"
            >事件报警<i></i
          ></span>
        </div>
      </div>
    </vue-scroll> -->
    <top-left-alarm />
    <!-- <div
      :class="{
        'absolute t0 r0 f-right eventHt p-sm': !$g.isDepart,
        h50: $g.isDepart,
      }"
    > -->
    <top-left-table></top-left-table>
    <!-- </div> -->
  </div>
</template>

<script>
import TopLeftAlarm from './TopLeftAlarm';
import TopLeftTable from './TopLeftTable';
export default {
  name: 'TopLeft',
  data() {
    return {};
  },
  components: {
    TopLeftAlarm,
    TopLeftTable,
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.eventHt {
  height: 95%;
}
</style>
