<template>
  <div class="full-width full-height">
    <div class="hd">
      <title-com text="事件日历" />
    </div>
    <vue-scroll :ops="$root.scrollOpsY" style="height: calc(100% - 36px);">
      <a-calendar
        :fullscreen="false"
        :header-render="headerRender"
        @panelChange="onPanelChange"
      >
        <!--  <span
          slot="dateCellRender"
          slot-scope="value"
          :title="getListData(value)"
          class="events block text-center ellipsis"
          @click="goView(value)"
        >
          {{ getListData(value) }}
        </span> -->
        <span
          slot="dateFullCellRender"
          slot-scope="value"
          :title="getListData(value)"
          class="text-center wd-50 block relative"
          :class="{
            'text-white': getCurrentMonthDay(value),
            'text-lightblue-today': getCurrentDay(value),
            'current-day-alarm': getCurrentDay(value) && getAlarmDay(value),
            'other-day-alarm': getAlarmDay(value),
          }"
        >
          {{ getDay(value)
          }}<span class="absolute r0 t0" v-show="getCurrentDay(value)">今</span>
        </span>
      </a-calendar>
    </vue-scroll>
  </div>
</template>
<script>
import TitleCom from './TitleCom';
import moment from 'moment';
export default {
  name: 'BotRight',
  data() {
    return {
      dateList: [],
      queryTime: moment().format('YYYY-MM'),
      panelDay: moment(),
    };
  },
  computed: {},
  components: {
    TitleCom,
  },
  watch: {},
  created() {
    this._getNumByDay();
  },
  methods: {
    onPanelChange(value) {
      // eslint-disable-next-line no-console
      console.log(moment(value).format('YYYY-MM-DD'));
      this.panelDay = moment(value);
      this.queryTime = moment(value).format('YYYY-MM');
      this._getNumByDay();
    },
    getDay(value) {
      return moment(value).format('DD');
    },
    // eslint-disable-next-line no-unused-vars
    getCurrentMonthDay(value) {
      const month = moment(value).format('MM');
      const currentMon = this.panelDay.format('MM');
      return month === currentMon;
    },
    getCurrentDay(value) {
      const day = moment(value).format('MM-DD');
      const currentDay = this.panelDay.format('MM-DD');
      return day === currentDay;
    },
    getAlarmDay(value) {
      const currentDay = moment(value).format('YYYY-MM-DD');
      return this.dateList.includes(currentDay);
    },
    // eslint-disable-next-line no-unused-vars
    headerRender({ value, type, onChange, onTypeChange }) {
      const start = 0;
      const end = 12;
      const monthOptions = [];
      const current = value.clone();
      const localeData = value.localeData();
      const months = [];
      for (let i = 0; i < 12; i++) {
        current.month(i);
        months.push(localeData.monthsShort(current));
      }
      for (let index = start; index < end; index++) {
        monthOptions.push(
          <a-select-option class="month-item" key={`${index}`}>
            {months[index]}
          </a-select-option>
        );
      }
      const month = value.month();
      const year = value.year();
      const options = [];
      for (let i = 1970; i < 2041; i += 1) {
        options.push(
          <a-select-option key={i} value={i} class="year-item">
            {i}
          </a-select-option>
        );
      }
      return (
        <div class="p-sm flex flex-end">
          <a-select
            size="small"
            dropdownMatchSelectWidth={false}
            class="my-year-select m-r-xs"
            onChange={(newYear) => {
              const now = value.clone().year(newYear);
              onChange(now);
            }}
            value={String(year)}
          >
            {options}
          </a-select>

          <a-select
            size="small"
            dropdownMatchSelectWidth={false}
            value={String(month)}
            onChange={(selectedMonth) => {
              const newValue = value.clone();
              newValue.month(parseInt(selectedMonth, 10));
              onChange(newValue);
            }}
          >
            {monthOptions}
          </a-select>
        </div>
      );
    },
    _getNumByDay() {
      let params = {
        queryTime: this.queryTime,
      };
      this.$api.aiCenterY.getNumByDay(params).then((res) => {
        if (res.code === 200) {
          this.dateList = res.data.map((item) => item.sj);
          // console.error('haha', this.dateList);
        }
      });
    },
    getListData(value) {
      let count;
      let year = String(value.year());
      let month = this.formatDate(value.month() + 1);
      let day = this.formatDate(value.date());
      this.dateList.forEach((item) => {
        if (year == item.year && month == item.month && day == item.day) {
          count = item.num;
        }
      });

      return count || '';
    },
    formatDate(date) {
      if (date < 10) {
        date = '0' + date;
      } else {
        date = String(date);
      }
      return date;
    },

    goView(value) {
      // console.error(moment(value).format('YYYY-MM-DD'));
      let query = moment(value).format('YYYY-MM-DD');
      this.$router.push({
        path: '/ai-center/event-all',
        query: {
          time: query,
        },
      });
    },
  },
};
</script>

<style scoped>
.events {
  background: rgba(32, 52, 83, 1);
  color: rgba(134, 179, 233, 1);
  border-radius: 3px;
  font-size: 12px;
}
.wd-50 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.current-day-alarm {
  background: #06526f;
}
.other-day-alarm {
  background: #44400c;
}

::v-deep .ant-fullcalendar-cell,
::v-deep .ant-fullcalendar th {
  border: 1px solid #203352;
}
.text-lightblue-today {
  color: #40bfcd;
}
.text-lightblue-today span.absolute {
  background: rgba(34, 59, 98, 0.5);
  padding: 0 4px;
  transform: scale(0.7);
  transform-origin: right top;
  border-radius: 4px;
}
</style>
