<template>
  <div class="full-width ht-per-50">
    <div class="m-t-sm m-b-xs flex justify-between items-center">
      <div class="flex items-center">
        <title-com text="AI分析方案" />
        <span class="text-lightblue m-r-sm">{{ Num }}</span>
        <i class="r-add pointer" @click="addHandle"></i>
      </div>
      <i class="icon-full pointer" @click="goViewSchema"></i>
    </div>
    <vue-scroll :ops="$root.scrollOpsY" class="scroll-ht">
      <div class="event-alarm ">
        <div
          class="p-sm text-lightblue relative"
          v-for="item in analysedList"
          :key="item.schemeId"
          @click="mouseEnter(item)"
        >
          <transition name="animate">
            <div
              v-if="item.show"
              class="flex flex-end items-center full-height bg-f-item absolute l0 t0 full-width z-999 f20"
            >
              <a-icon
                type="play-circle"
                class="m-r-sm pointer"
                v-show="false"
              />
              <i
                class="icon-cameral m-r-sm pointer"
                @click="viewCamera($event, item)"
              ></i>
              <a-icon
                :type="getTypeCls(item)"
                @click="toggleStatus(item)"
                class="m-r-sm pointer"
                v-show="false"
              />
              <a-icon
                type="rest"
                class="m-r-sm pointer"
                @click="deleteHandle(item)"
                v-show="false"
              />
              <a-icon
                type="edit"
                class="m-r-sm pointer"
                @click="editHandle(item)"
                v-show="false"
              />
            </div>
          </transition>
          <div class="flex justify-between items-center">
            <i class="icon-project m-r-xs"></i>
            <span class="flex-one ellipsis m-r-sm">{{ item.planName }}</span>
            <span class="m-r-sm pointer wd40">
              <a-icon type="experiment" class="m-r-xs ve-m" />
              {{ item.beginDate ? item.beginDate : '--' }}
            </span>
            <span class="text-white wd10">{{ item.cameraCount }}</span>
          </div>
        </div>
      </div>
    </vue-scroll>
    <top-right-analyzed-dialog
      :isEdit="isEdit"
      :editData="editData"
      :visible="addVisible"
      @sure="sureHandle"
      @close="closeAnalyzed"
    />
  </div>
</template>
<script>
import TitleCom from './TitleCom';
import TopRightAnalyzedDialog from '@/components/TopRightAnalyzedDialog';
import { mapActions, mapMutations, mapState } from 'vuex';
import { CODE_OK } from '@/request/config_code';
import { wgs84_to_gcj02 } from '@/tools/wgs84_to_gcj02.js';
export default {
  data() {
    return {
      groupAddVisible: false,
      datalist: [
        /* {
          id: 0,
          show: false,
        },
        {
          id: 1,
          show: false,
        },
        {
          id: 2,
          show: false,
        }, */
      ],
      Num: '',
      number: '',
      addVisible: false,
      isEdit: false,
      editData: {},
    };
  },
  components: { TitleCom, TopRightAnalyzedDialog },
  computed: {
    ...mapState({
      userInfo: (state) => state.login.userInfo,
      cameraMapList: (state) => state.aiCenter.cameraMapList,
      analysedList: (state) => state.aiCenter.analysedList,
      analyseList: (state) => state.aiCenter.analyseList,
    }),
  },
  mounted() {
    // this.getCameraGroupCondition();
    this._getAnalyInfos();
  },
  methods: {
    _getAnalyInfos() {
      /* this.$api.aiCenterY.getAnalyInfos().then((res) => {
        if (res.code === 200) {
          // console.error('哈哈哈',res.data);
          this.datalist = res.data.map((item) => {
            this.Num = item.count;
            item.show = false;
            return item;
          });
        }
        this.number = this.datalist[0].cameraNum;
      }); */
      let params = {
        currPage: 0,

        pageSize: 0,
      };
      /*       this.loading = true; */
      this.$api.aiCenterY.getAnalysePLanList(params).then((res) => {
        if (res.code === CODE_OK) {
          this.datalist = res.data.map((item) => {
            // this.Num = item.cameraCount;
            item.show = false;
            item.viewCameraShow = false;
            return item;
          });
          this.Num = res.total;
          this.setAnalysedList(this.datalist);
        } else {
          this.$message.error('拉取列表失败');
        }
        /*   this.loading = false; */
      });
    },
    viewCamera(e, item) {
      e.stopPropagation();
      //清空其他模块数据
      let analyseList = this.analyseList.map((item) => {
        item.show = false;
        item.viewCameraShow = false;
        return item;
      });
      this.setAnalyseList(analyseList);
      item.viewCameraShow = !item.viewCameraShow;

      if (!item.viewCameraShow) {
        this.setCameraMapList([]);
        item.show = item.viewCameraShow;

        return;
      }
      this.$api.aiCenterY.queryCamerasByPlanId(item.planId).then((res) => {
        if (res.code === 200) {
          // this.currentCameraList = res.data;
          let list = res.data.map((item) => {
            item.type = '0';
            let lonLat = wgs84_to_gcj02(item.longitude, item.latitude);
            item.long = lonLat[0];
            item.lat = lonLat[1];
            return item;
          });
          this.setCameraMapList(list);
        }
      });
    },
    toggleStatus(item) {
      /*  item.schemeStatus = item.schemeStatus === 1 ? 0 : 1;
      this.modifyAnStatus(item.schemeId); */
      item.planStatus = item.planStatus === 0 ? 1 : 0;
      let params = {
        planId: item.planId,
        planStatus: item.planStatus,
        userId: this.userInfo.userId,
      };
      this.$api.aiCenterY.startOrStopPlan(params).then((res) => {
        if (res.code === CODE_OK) {
          this.$message.success(
            `${item.planStatus === 1 ? '启用' : '禁止'}成功！`
          );
        }
      });
    },
    getTypeCls(item) {
      return item.planStatus === 1 ? 'check-circle' : 'minus-circle';
    },
    deleteHandle(item) {
      this.$confirm({
        title: '提示',
        content: '你确定删除该数据吗?',
        onOk: () => {
          /* this.deleteAnaStatus(item.schemeId).then((res) => {
            if (res.code === 200) {
              this._getAnalyInfos();
            }
          }); */
          this.$api.aiCenterY.deleteTask(item.planId).then((res) => {
            if (res.code === CODE_OK) {
              this.$message.success('删除该数据成功！');
              this._getAnalyInfos();
            } else {
              this.$message.error('删除该数据失败！');
            }
          });
        },
        onCancel: () => {},
      });
    },
    closeGroup() {
      this.groupAddVisible = false;
    },
    openGroupHandle() {
      this.groupAddVisible = true;
    },
    mouseEnter(item) {
      let datalist = this.analysedList.map((item) => {
        item.show = false;
        item.viewCameraShow = false;
        return item;
      });
      item.show = true;
      this.setAnalysedList(datalist);
    },
    mouseLeave(item) {
      item.show = false;
    },
    addHandle() {
      if(!this.hasPermission(1092121311)) {
        this.permissionWarning();
        return;
      }
      this.addVisible = true;
      this.isEdit = false;
    },
    closeAnalyzed() {
      this.addVisible = false;
      this.isEdit = false;
      this._getAnalyInfos();
    },
    sureHandle() {
      this.closeAnalyzed();
    },
    goViewSchema() {
      this.$router.push({ path: '/ai-center/analyzed-schema' });
    },
    editHandle(item) {
      this.isEdit = true;
      this.addVisible = true;
      this.editData = item;
    },
    ...mapMutations(['setCameraMapList', 'setAnalyseList', 'setAnalysedList']),
    ...mapActions([
      'getCameraGroupCondition',
      'modifyAnStatus',
      'deleteAnaStatus',
    ]),
  },
};
</script>
<style scoped>
.animate-enter-active,
.animate-leave-active {
  transition: all 0.5s;
}
.animate-enter,
.animate-leave-to {
  transform: translate3d(100%, 0, 0);
}
.wd40 {
  width: 50%;
}
.wd10 {
  width: 10%;
}
.scroll-ht {
  height: calc(100% - 50px) !important;
}
</style>
