<template>
  <div class="full-width ht50">
    <div
      class="flex flex-one justify-center items-center justify-between m-b-xs"
    >
      <title-com text="AI分析中" />
      <div>
        <span class="m-r-sm">
          <span class="text-lightblue  m-r-xxs">正在执行方案:</span>
          <span class="text-white">{{ planNum }}</span>
        </span>
        <span>
          <span class="text-lightblue m-r-xxs">正在分析摄像机:</span>
          <span class="text-white">{{ cameraNum }}</span>
        </span>
      </div>
    </div>
    <div class="on-con relative p-h-md full-width ht-con">
      <span class="cor absolute t-lf"></span>
      <span class="cor absolute t-rf"></span>
      <span class="cor absolute b-lf"></span>
      <span class="cor absolute b-rf"></span>
      <div class="con-main flex items-center full-height">
        <vue-scroll :ops="$root.scrollOpsY" style="height:100%;">
          <!--@mouseleave="mouseLeave(item)"-->
          <div
            v-for="item in analyseList"
            :key="item.schemeId"
            class="relative item-bg m-b-xs"
            @click="mouseEnter(item)"
          >
            <!-- 点击动画 -->
            <transition name="animate">
              <div
                v-if="item.show"
                class="flex f20 flex-end items-center text-lightblue full-height bg-f-item absolute l0 t0 full-width z-999"
              >
                <i
                  class="icon-cameral m-r-sm pointer"
                  @click="viewCamera($event, item)"
                ></i>
                <a-icon
                  v-show="false"
                  :type="getTypeCls(item)"
                  @click="toggleStatus(item)"
                  class="m-r-sm pointer"
                />
              </div>
            </transition>
            <div
              class="flex items-center full-height p-w-lg justify-between text-lightblue m-b-xs"
            >
              <div class="flex-one flex items-center">
                <i class="m-r-sm icon-book"></i>
                <div class="flex-one">
                  <!-- <i class="icon-r m-r icon-pho">1</i> -->
                  <div class="text-name">
                    <span class="ellipsis wd block m-b-xs">{{
                      item.planName
                    }}</span>
                    <span class="text-white">{{ item.cameraCount }}</span>
                    <!--  <span>{{ item.online }}/{{ item.cameraNum }}</span> -->
                  </div>
                </div>
                <i class="icon-radius"></i>
                <!--  <a-progress
                  :percent="getPercent(item)"
                  status="active"
                  :show-info="false"
                  size="small"
                  strokeColor="#86B3E9"
                  :strokeWidth="3"
                /> -->
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>
<script>
import TitleCom from './TitleCom';
import { mapActions, mapMutations, mapState } from 'vuex';
import { wgs84_to_gcj02 } from '@/tools/wgs84_to_gcj02.js';
export default {
  data() {
    return {
      cameraNum: 0,
      planNum: 0,
      datalist: [],
      // currentCameraList: [],
    };
  },
  components: {
    TitleCom,
  },
  created() {
    this._getAnalyInfo();
  },
  computed: {
    ...mapState({
      cameraMapList: (state) => state.aiCenter.cameraMapList,
      analyseList: (state) => state.aiCenter.analyseList,
      analysedList: (state) => state.aiCenter.analysedList,
    }),
  },
  methods: {
    _getAnalyInfo() {
      this.$api.aiCenterY.queryExecutingPlan().then((res) => {
        if (res.code === 200) {
          this.datalist = res.data.list.map((item) => {
            // console.log('哈哈哈',item);
            item.show = false;
            item.viewCameraShow = false;
            return item;
          });
          this.cameraNum = res.data.cameraCount;
          this.planNum = res.data.planCount;
          this.setAnalyseList(this.datalist);
        } else {
          this.$message.error('拉取列表失败');
        }
        // console.log('111111111',this.datalist);
      });
    },
    toggleStatus(item) {
      item.schemeStatus = item.schemeStatus === 1 ? 0 : 1;
      this.modifyAnStatus(item.schemeId).then((res) => {
        if (res.code === 200) {
          this._getAnalyInfo();
        }
      });
    },
    getTypeCls(item) {
      // 动画上面的图标 schemeStatus===1 是对勾  ===0是横杠
      return item.schemeStatus === 1 ? 'check-circle' : 'minus-circle';
    },
    mouseEnter(item) {
      let datalist = this.analyseList.map((item) => {
        item.show = false;
        item.viewCameraShow = false;
        return item;
      });
      item.show = true;
      this.setAnalyseList(datalist);
    },
    mouseLeave(item) {
      item.show = false;
    },
    getPercent(item) {
      return item.cameraNum >= 0
        ? Math.round((item.online / item.cameraNum) * 100)
        : 0;
    },
    viewCamera(e, item) {
      e.stopPropagation();
      //清空其他模块数据
      let analysedList = this.analysedList.map((item) => {
        item.show = false;
        item.viewCameraShow = false;
        return item;
      });
      this.setAnalysedList(analysedList);
      item.viewCameraShow = !item.viewCameraShow;

      if (!item.viewCameraShow) {
        this.setCameraMapList([]);
        item.show = item.viewCameraShow;

        return;
      }
      this.setAnalyseList(this.datalist);
      this.$api.aiCenterY.queryCamerasByPlanId(item.planId).then((res) => {
        if (res.code === 200) {
          // this.currentCameraList = res.data;
          let list = res.data.map((item) => {
            item.type = '0';
            let lonLat = wgs84_to_gcj02(item.longitude, item.latitude);
            item.long = lonLat[0];
            item.lat = lonLat[1];
            return item;
          });
          this.setCameraMapList(list);
        }
      });
    },
    ...mapMutations(['setCameraMapList', 'setAnalyseList', 'setAnalysedList']),
    ...mapActions(['modifyAnStatus']),
  },
};
</script>
<style scoped>
.animate-enter-active,
.animate-leave-active {
  transition: all 0.5s;
}
.animate-enter,
.animate-leave-to {
  transform: translate3d(100%, 0, 0);
}
.ht50 {
  height: 50%;
}
.ht-con {
  height: calc(100% - 30px);
}
.item-bg {
  background: url('~@/assets/AI/analysis_bg.png') no-repeat;
  width: 100%;
  height: 52px;
  background-size: 100% 100%;
}
.text-name {
  line-height: 1.2;
}
.icon-book {
  background: url('~@/assets/AI/book.png') no-repeat;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
}
.icon-radius {
  background: url('~@/assets/AI/radius.png') no-repeat;
  width: 20px;
  height: 15px;
  background-size: 100% 100%;
}

@media screen and (max-width: 1920px) {
  .wd {
    width: 350px;
  }
}

@media screen and (max-width: 1370px) {
  .wd {
    width: 250px;
  }
}
</style>
