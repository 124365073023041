<template>
  <div class="full-width full-height">
    <div class="top-container">
      <top-component></top-component>
    </div>
    <div class="bot-container">
      <bottom-component></bottom-component>
    </div>
  </div>
</template>

<script>
import TopComponent from './TopComponent';
import BottomComponent from './BottomComponent';
import { mapMutations } from 'vuex';
export default {
  name: 'AiCenterIndex',
  beforeRouteLeave(to, from, next) {
    this.setCameraMapList([]);
    next();
  },
  data() {
    return {};
  },
  components: {
    TopComponent,
    BottomComponent,
  },
  computed: {},
  watch: {},
  methods: {
    ...mapMutations(['setCameraMapList']),
  },
};
</script>

<style scoped>
.top-container {
  height: 70%;
}
.bot-container {
  height: 30%;
  margin-top: 10px;
}
</style>
