import { mapMutations } from 'vuex';
import EventAlarmDetail from '@/components/EventAlarmDetail';

//import axios from "axios";

const AMap = window.AMap;
const AMapUI = window.AMapUI;

export default {
  name: 'traffic-amap',
  components: {
    EventAlarmDetail
  },
  props: {
    baseTopAdcodes: {
      type: Number,
      default: 100000
    },
    baseTopAdcodesName: {
      type: String,
      default: '中国'
    },
    loadCurArea: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let googleLayer = new AMap.TileLayer.Satellite({
      //zooms:[15,24],    //可见级别
      getTileUrl:
        'http://mt2.google.cn/vt/lyrs=s@817&hl=zh-CN&gl=cn&x=[x]&y=[y]&z=[z]&s=Galil',
      zIndex: 20,
      //tileUrl : 'http://www.google.cn/maps/vt?lyrs=s@817&gl=cn&x=[x]&y=[y]&z=[z]',
      opacity: 1
    });
    let satellite = new AMap.TileLayer.Satellite();
    let roadNet = new AMap.TileLayer.RoadNet();
    let trafficLayer = new AMap.TileLayer.Traffic({
      zIndex: 40
    });
    return {
      googleLayer,
      satellite,
      roadNet,
      trafficLayer,
      mapInstance: null,
      districtExplorer: null,
      currentAreaNode: null,
      $tipMarkerContent: null,
      tipMarker: null,
      pointSimplifierIns: null,

      //摄像机选中图标
      cameraGifUrl: {
        '1': require('@/assets/traffic_map/icon/camera-gif.gif'),
        '2': require('@/assets/traffic_map/icon/camera-gif-2.gif'),
        '3': require('@/assets/traffic_map/icon/camera-gif-3.gif'),
        '4': require('@/assets/traffic_map/icon/camera-gif-5.gif'),
        '5': require('@/assets/traffic_map/icon/camera-gif-5.gif'),
        '6': require('@/assets/traffic_map/icon/camera-gif-6.gif'),
        '7': require('@/assets/traffic_map/icon/camera-gif-7.gif')
      },
      //摄像机正常图标
      cameraImgUrl: {
        '1': require('@/assets/traffic_map/icon/newIcon/road-online.png'),
        '2': require('@/assets/traffic_map/icon/newIcon/bridge-online.png'),
        '3': require('@/assets/traffic_map/icon/newIcon/tunnel-online.png')
      }, //require('@/assets/images/traffic_map/icon/camera.png'),

      cameraPanoramaImgUrl: require('@/assets/traffic_map/icon/camera-panorama.png'),
      cameraPanoramaGifUrl: require('@/assets/traffic_map/icon/camera-panorama-gif.gif'),

      cameraIconWidth: 30, //35,
      cameraIconHeight: 30, //40*88/76

      cameraVideoInfo: {},
      videoUrl: '',
      currentPlayCamera: {},
      cameraInfoWindow: null,

      cameraMarkVisable: false,
      cameraImagesVisable: false,
      cameraInfoVisable: false,
      cameraInfoEditVisable: false,

      videoDialogElement: null,

      //baseTopAdcodes                   : 100000,

      resolutionValue: '20', //视频清晰度

      currentMarkers: [],
      currentPosMarker: {},
      backMark: null, //当前选中或之前选中的mark点
      chosedMark: null,
      chosedMarkData: {},
      dragMarkerGhostStyle: {},
      infoWindow: null,
      editCameraInfoSign: false, //用户修改摄像机权限

      mapLayerType: 'base',
      eventClickMarker: null,
      currPolyline: null,

      renderPolygon: null,
      cameraDetailId: '',
      cameraDetail: {}
    };
  },

  beforeMount() {
    //加载点位marker事件监听
    this.loadDefaultAmapEvent();
  },
  mounted() {
    this.mapInit();
    document.oncontextmenu = function() {
      return false;
    };
  },
  methods: {
    /*...mapActions([
        'getCameraPlayUrl',
    ]),*/
    ...mapMutations(['setSelectCameraIndex', 'setLatResult', 'setDragMove']),

    mapSetCenter(pos) {
      // 传入经纬度，设置地图中心点
      let position = new AMap.LngLat(pos[0], pos[1]); // 标准写法
      // 简写 var position = [116, 39];
      this.mapInstance.setCenter(position);
    },
    /**
     * 地图初始化
     */
    mapInit() {
      let that = this;
      this.$nextTick(() => {
        this.mapInstance = new AMap.Map('mapcontainer', {
          cursor: 'default',
          resizeEnable: true,
          animateEnable: true,
          disableSocket: true,
          autoSetFitView: true,
          //viewMode: '3D',
          //showLabel: false,//不显示地图文字标记
          labelzIndex: 130,
          topAdcodes: [that.baseTopAdcodes],
          pitch: 0,
          zoom: 8,
          //mapStyle: 'amap://styles/1ca5820aeb977f88e1b07925d4ff1d4c',
          // mapStyle: 'amap://styles/45b1ef3ccee882924052c4ed0baa4865',fixsxy
          mapStyle: 'amap://styles/darkblue',
          layers: [
            // baseLayer,fixsxy
            //layer,
            //googleLayer,
            /* this.trafficLayer */
            //disCountry
          ] //图层
        });

        //显示鹰眼
        /*  this.mapInstance.plugin(['AMap.OverView'], function() {
          let view = new AMap.OverView({
            isOpen: false
          });
          that.mapInstance.addControl(view);
        }); */
        /* let oS = new AMap.Scale();
        this.mapInstance.addControl(oS); */
        //当前聚焦的区域
        this.currentAreaNode = null;

        //鼠标hover提示内容
        let ele = document.createElement('div'); //  .getElementById('maptip');
        ele.className = 'tipMarker top';
        this.$tipMarkerContent = ele;
        this.tipMarker = new AMap.Marker({
          content: this.$tipMarkerContent,
          offset: new AMap.Pixel(0, 0),
          bubble: true
        });

        // 地图加载完成后触发
        that.mapInstance.on('complete', function() {
          that.$emit('amapComplete');
          //that.mapInstance.panBy(-250, -300);
          //地图内容，地图盖板
          that.initMapContent();
          //地图缩放
          that.mapInstance.on('zoomend', function(e) {
            that.mapChanged(e);

            /* let zom = that.mapInstance.getZoom(),
              feaps = that.districtExplorer.getAllFeaturePolygons();
            
            if (zom >= 15) {
            
            } else if ((!feaps || feaps.length === 0) && !that.loadCurArea) {
               
              that.renderAreaPolygons(that.currentAreaNode);
            }
 */
          });
          // 地图移动
          that.mapInstance.on('moveend', function(e) {
            that.mapChanged(e);
          });
          //地图大小变化
          that.mapInstance.on('resize', function(e) {
            that.mapChanged(e);
          });

          /* setTimeout(() => {
            //加载路线图层
            that.showLineLayer();
          }, 1000); */
        });

        this.editCameraInfoSign = true; //this.$_btnPermissionCheck(10931010);
      });
    },

    /**
     * 地图大小、层级等变化
     */
    mapChanged() {
      let that = this;

      clearTimeout(window.setMapTimeout);
      window.setMapTimeout = setTimeout(function() {
        if (that.infoWindow) {
          that.infoWindow.close();
        }
        that.$emit('amapChanged', that.currentAreaNode.adcode);
      }, 500);
    },

    /**
     * 创建地图显示内容
     */
    initMapContent() {
      let that = this;

      that.mapInstance.AmbientLight = new AMap.Lights.AmbientLight(
        [1, 1, 1],
        0.3
      );
      that.mapInstance.DirectionLight = new AMap.Lights.DirectionLight(
        [0, 0, 1],
        [1, 1, 1],
        0.5
      );

      AMapUI.load(['ui/geo/DistrictExplorer', 'lib/$'], function(
        DistrictExplorer
      ) {
        //创建一个实例
        let distExp = new DistrictExplorer({
          eventSupport: true, //打开事件支持
          map: that.mapInstance
        });

        //监听feature的hover事件
        distExp.on('featureMouseout featureMouseover', function(e, feature) {
          that.toggleHoverFeature(
            feature,
            e.type === 'featureMouseover',
            e.originalEvent ? e.originalEvent.lnglat : null
          );
        });

        //监听鼠标在feature上滑动
        distExp.on('featureMousemove', function(e) {
          //更新提示位置
          that.tipMarker.setPosition(e.originalEvent.lnglat);
        });

        //feature被点击
        distExp.on('featureClick', function() {
          that.$root.$emit('removeEventMarker', '');
        });

        //外部区域被点击
        distExp.on('outsideClick', function() {
          that.$root.$emit('removeEventMarker', '');
        });
        that.districtExplorer = distExp;

        //加载指定区块，默认全国
        that.switch2AreaNode(that.baseTopAdcodes);
      });
    },

    /**
     * 根据区块Hover状态设置相关样式
     */
    toggleHoverFeature(feature, isHover, position) {
      this.tipMarker.setMap(isHover ? this.mapInstance : null);

      if (!feature) {
        return;
      }

      let props = feature.properties;

      if (isHover) {
        //更新提示内容
        this.$tipMarkerContent.innerHTML = props.name;
        //更新位置
        this.tipMarker.setPosition(position || props.center);
      }

      //更新相关多边形的样式
      let polys = this.districtExplorer.findFeaturePolygonsByAdcode(
        props.adcode
      );
      for (let i = 0, len = polys.length; i < len; i++) {
        polys[i].setOptions({
          strokeColor: isHover ? '#63a5fe' : '#0c2254',
          fillColor: isHover ? '#0e397c' : '#0d4692',
          //fillOpacity: isHover ? 0.5 : 1
          zIndex: isHover ? 2 : 1
        });
      }
    },

    removeCurrentPosMarker() {
      this.mapInstance.remove(this.currentPosMarker);
    },

    /**
     * 绘制某个区域的边界
     */
    renderAreaPolygons(areaNode) {
      // console.error(222222222222222);
      //
      let _this = this;
      //清除已有的绘制内容
      this.districtExplorer.clearFeaturePolygons();
      if (this.loadCurArea) {
        let fillColor = 'rgba(8,34,74,0.75)';
        let fillOpacity = 0.9;
        if (this.renderPolygon) {
          this.mapInstance.remove(this.renderPolygon);
        }

        new AMap.DistrictSearch({
          extensions: 'all',
          subdistrict: 0
        }).search(_this.baseTopAdcodesName, function(status, result) {
          var outer = [
            new AMap.LngLat(-360, 90, true),
            new AMap.LngLat(-360, -90, true),
            new AMap.LngLat(360, -90, true),
            new AMap.LngLat(360, 90, true)
          ];
          var holes = result.districtList[0].boundaries;

          var pathArray = [outer];
          pathArray.push.apply(pathArray, holes);
          _this.renderPolygon = new AMap.Polygon({
            pathL: pathArray,
            //线条颜色，使用16进制颜色代码赋值。默认值为#006600
            strokeColor: 'orange',
            strokeWeight: 4,
            //轮廓线透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
            strokeOpacity: 0.5,
            //多边形填充颜色，使用16进制颜色代码赋值，如：#FFAA00
            fillColor: fillColor,
            //多边形填充透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
            fillOpacity: fillOpacity,
            //轮廓线样式，实线:solid，虚线:dashed
            strokeStyle: 'solid',
            /*勾勒形状轮廓的虚线和间隙的样式，此属性在strokeStyle 为dashed 时有效， 此属性在    
                ie9+浏览器有效 取值： 
                实线：[0,0,0] 
                虚线：[10,10] ，[10,10] 表示10个像素的实线和10个像素的空白（如此反复）组成的虚线
                点画线：[10,2,10]， [10,2,10] 表示10个像素的实线和2个像素的空白 + 10个像素的实 
                线和10个像素的空白 （如此反复）组成的虚线*/
            strokeDasharray: [10, 2, 10]
          });
          _this.renderPolygon.setPath(pathArray);
          _this.mapInstance.add(_this.renderPolygon);
        });
      } else {
        let fillColor = this.mapLayerType === 'google' ? 0 : '#0d4692',
          // this.mapInstance.add(this.googleLayer);
          // let fillColor = this.mapLayerType === 'google' ? 0 : this.googleLayer,
          strokeColor = '#2b3a7c', // '#0c3574',
          fillOpacity = this.mapLayerType === 'google' ? 0 : 0;

        if (areaNode.adcode !== 100000) {
          //绘制子区域
          this.districtExplorer.renderSubFeatures(areaNode, function() {
            return {
              cursor: 'default',
              bubble: true,
              strokeColor: strokeColor, //线颜色
              strokeOpacity: 1, //线透明度
              strokeWeight: 1, //线宽
              fillColor: fillColor, //填充色
              fillOpacity: fillOpacity, //填充透明度
              zIndex: 1
            };
          });
        }

        //绘制父区域
        this.districtExplorer.renderParentFeature(areaNode, {
          cursor: 'default',
          bubble: true,
          strokeColor: strokeColor, //线颜色
          strokeOpacity: 1, //线透明度
          strokeWeight: 0, //线宽
          fillColor: fillColor, //填充色
          fillOpacity: 0.4, //填充透明度
          zIndex: 1
        });
      }
      if (window.innerWidth >= 1920) {
        this.mapInstance.setZoom(8);
      }
    },

    /**
     * 加载区域
     */
    loadAreaNode(adcode, callback) {
      let _this = this;

      this.districtExplorer.loadAreaNode(adcode, function(error, areaNode) {
        //console.info('areaNode  ',adcode,areaNode);
        _this.districtExplorer.clearFeaturePolygons();
        if (error) {
          if (callback) {
            callback(error);
          }
          //console.error('loadAreaNode error ', error);
          return;
        }

        if (callback) {
          callback(null, areaNode);
        }
      });

      return false;
    },

    /**
     * 切换区域后刷新显示内容
     */
    refreshAreaNode(areaNode) {
      this.districtExplorer.setHoverFeature(null);

      //更新地图视野
      this.mapInstance.setBounds(areaNode.getBounds());
      this.renderAreaPolygons(areaNode);
    },

    /**
     * 切换区域
     */
    switch2AreaNode(adcode, callback) {
      //let currentAreaNode = null;
      if (
        this.currentAreaNode &&
        '' + this.currentAreaNode.getAdcode() === '' + adcode
      ) {
        return;
      }

      let that = this;
      this.loadAreaNode(adcode, function(error, areaNode) {
        if (error) {
          if (callback) {
            callback(error);
          }
          return;
        }
        that.currentAreaNode = areaNode; //this.currentAreaNodeBack =
        //设置当前使用的定位用节点
        that.districtExplorer.setAreaNodesForLocating([that.currentAreaNode]);
        that.refreshAreaNode(areaNode);
        if (callback) {
          callback(null, areaNode);
        }
      });
    },

    /**
     * 鼠标hover，显示对应区块
     *
     * @param adcode
     */
    districtHover(adcode) {
      this.districtExplorer.setHoverFeature(
        this.currentAreaNode.getSubFeatureByAdcode(adcode)
      );
    },

    /**
     * 关闭播放器
     */
    cameraInfoWindowClose() {
      let that = this;

      that.cameraMarkVisable = false;
      that.currentPlayCamera = {};
    },

    /**
     * 打开播放器
     * @param record
     */
    cameraInfoWindowShow(record) {
      let that = this;

      if (record && record.data && record.data.cameraName) {
        //that.currentPlayCamera = record.data;
        // console.info('cameraInfoWindowShow ', record.data, pixel);

        // 传入经纬度，设置地图中心点
        let position = new AMap.LngLat(
          record.data.position[0],
          record.data.position[1]
        ); // 标准写法
        // 简写 var position = [116, 39];
        this.mapInstance.setCenter(position);

        that.cameraMarkVisable = true;
        that.cameraDetailId = record.data.id;
        that.cameraDetail = record.data;

        //鼠标位置
        /* if (pixel && pixel.x && pixel.y) {
          that.$refs.amapPlayer.setWindowSite(pixel.x, pixel.y);
        }
        that.$nextTick(() => {
          //setTimeout(() => {
          that.$refs.amapPlayer.getVideoUrlToPlay(record.data);
          //}, 1000);
        }); */
      }
      /* if (record && isFromCollect) {
        that.cameraMarkVisable = true;
        that.$nextTick(() => {
          //setTimeout(() => {
          //收藏列表无法给经纬度，故鼠标位置随便写的
          that.$refs.amapPlayer.setWindowSite(663, 207);
          that.$refs.amapPlayer.getVideoUrlToPlay(record);
          //}, 1000);
        });
      } */
    },

    /**
     * 动态获取摄像机点位图标，类型、状态
     */
    getMarkerIcon(type) {
      let that = this,
        iconBase = this.cameraImgUrl;

      return new AMap.Icon({
        image: iconBase[type],
        size: new AMap.Size(that.cameraIconWidth, that.cameraIconHeight), // 图标尺寸
        imageSize: new AMap.Size(that.cameraIconWidth, that.cameraIconHeight) // 根据所设置的大小拉伸或压缩图片
      });
    },

    /**
     * 批量加载点数据创建摄像机marker
     * @param list
     */
    pointsSetIn(list) {
      let that = this,
        markerList = [];

      if (!this.infoWindow) {
        this.infoWindow = new AMap.InfoWindow({
          offset: new AMap.Pixel(0, -30),
          zoom: 8
        });
      }

      //that.mapInstance.clearMap();
      that.mapInstance.remove(that.currentMarkers);

      if (list.length) {
        let num = list.length;

        // 摄像机图标mouseover事件
        let markerhover = e => {
          that.infoWindow.setContent(e.target.content);
          that.infoWindow.open(that.mapInstance, e.target.getPosition());

          that.tipMarker.hide();
          //console.info('that.tipMarker.hide();');
        };

        // 摄像机图标mouseout事件
        let markermouseout = () => {
          // console.log("infoWindow=>触发离开事件");
          that.infoWindow.close();
          that.tipMarker.show();
        };

        // 加载点数据
        for (let i = 0, len = num; i < len; i++) {
          let marker = new AMap.Marker({
            position: [list[i].longitude, list[i].latitude], //new AMap.LngLat(list[i].longitude,list[i].latitude),
            zIndex: 301,
            offset: new AMap.Pixel(
              0 - that.cameraIconWidth / 2,
              0 - that.cameraIconHeight
            ),

            clickable: true,
            icon: that.getMarkerIcon(list[i].isEventAlarm),
            extData: {
              onlineStatus:
                list[i].onlineStatus === null || list[i].onlineStatus === 0
                  ? 0
                  : list[i].onlineStatus,
              cameraName: list[i].cameraName,
              cameraId: list[i].cameraId,
              cameraNum: list[i].cameraNum,
              organizationId: list[i].organizationId,
              cameraKmpile: list[i].cameraKmpile,
              cameraHmpile: list[i].cameraHmpile,
              roadId: list[i].roadId || list[i].road, // roadId
              regionCode: list[i].regionCode,
              position: [list[i].longitude, list[i].latitude],
              cameraType: list[i].cameraType,
              kmPile: list[i].kmPile,
              hmPile: list[i].hmPile,
              classify: list[i].classify,
              zoomlevel: list[i].zoomlevel,
              source: list[i].source,
              khPile: list[i].khPile,
              id: list[i].id,
              eventSummary: list[i].eventSummary,
              companyName: list[i].companyName,
              derection: list[i].derection,
              startTime: list[i].startTime,
              endTime: list[i].endTime,
              eventTypeName: list[i].eventTypeName,
              eventLevel: list[i].eventLevel,
              eventPlace: list[i].eventPlace,
              oid: list[i].oid,
              isOrNotBridge: list[i].isOrNotBridge,
              isOrNotTunnel: list[i].isOrNotTunnel
            }
          });

          if (
            ['zsy_qjsxj_hall_01', 'zsy_qjsxj_hall_02'].indexOf(
              list[i].cameraNum
            ) != -1
          ) {
            /*  marker.content = 'zsy_qjsxj_hall_01'== list[i].cameraNum ?'重庆国际博览中心N2号馆（高清）':'重庆国际博览中心N2号馆（超清）'; fixsxy*/
          } else {
            let sta = [
              '<span style="color:#b1bfdc">(离线)</span>',
              '',
              '<span style="color:#fe3708">(异常)</span>'
            ][list[i].onlineStatus];

            marker.content = `${list[i]['khPile']}${
              typeof sta === 'undefined'
                ? ' (未知)' + list[i].onlineStatus
                : sta
            }`;
          }

          // 摄像机图标点击事件
          marker.on('click', function(a) {
            let cameraInfo = a.target.getExtData();

            //播放器
            if (cameraInfo.onlineStatus === '1') {
              that.cameraInfoWindowShow({ data: cameraInfo });
            } else {
              that.$message.warning('此摄像机处于离线状态！无法播放视频！');
            }
          });

          // 摄像机图标mouseover事件
          marker.on('mouseover', markerhover);

          // 摄像机图标mouseout事件
          marker.on('mouseout', markermouseout);

          markerList.push(marker);
        }

        // 创建覆盖物群组，并将 marker 传给 OverlayGroup
        that.currentMarkers = new AMap.OverlayGroup(markerList);

        that.mapInstance.add(that.currentMarkers);
      }
    },

    /**
     * 移除marker标记
     * @param flag  marker数据
     */
    removeMarker(flag) {
      if (!flag) return;
      this.mapInstance.remove(flag);
    },

    loadDefaultAmapEvent() {
      //console.info('loadDefaultAmapEvent ',this.backMark);
      this.$root.$off('removeEventMarker');
      this.$root.$off('editEventMarkerLonlat');
      this.$root.$off('mapPan');

      //移除地图正在处理的marker标记
      this.$root.$on('removeEventMarker', () => {
        if (!this.chosedMark) {
          return false;
        }
        if (this.cameraMarkVisable) {
          this.backMark.show();
          return false;
        }
        //console.info(this.chosedMark);
        this.chosedMark.show();
        this.removeMarker(this.backMark);
        if (this.$refs.dragMarkerGhost) {
          this.$refs.dragMarkerGhost.$el.style.top = '-50px';
          this.$refs.dragMarkerGhost.$el.style.left = '-50px';
        }
      });

      //设置marker点位置
      this.$root.$on('editEventMarkerLonlat', nv => {
        this.backMark.setPosition(nv);
      });

      // eslint-disable-next-line no-unused-vars
      this.$root.$on('mapPan', nv => {
        this.mapInstance.panBy(0, 5);
      });
    },
    closeHandle() {
      this.cameraMarkVisable = false;
      this.cameraDetail = {};
    }
  }
};
