<template>
  <div class="full-width full-height">
    <traffic-amap-center
      ref="amapIns"
      @amapComplete="amapCompleteCallback"
      @amapClick="amapClickCallback"
      @amapChanged="mapChanged"
      :base-top-adcodes="regionCode"
      :base-top-adcodes-name="regionName"
      v-if="!$qiFang"
    ></traffic-amap-center>
    <qf-traffic-amap-center
      ref="amapIns"
      @amapClick="amapClickCallback"
      @amapChanged="amapCompleteCallback"
      @misReportParent="misHandle"
      v-if="$qiFang"
    ></qf-traffic-amap-center>
  </div>
</template>

<script>
import TrafficAmapCenter from '@/components/TrafficAmapCenter';
import QfTrafficAmapCenter from '@/components/QFTrafficAmapCenter';
import { mapMutations, mapActions, mapState } from 'vuex';
import { CODE_OK } from '@/request/config_code';
import { wgs84_to_gcj02 } from '@/tools/wgs84_to_gcj02.js';
export default {
  name: 'TopMain',
  components: {
    TrafficAmapCenter,
    QfTrafficAmapCenter,
  },
  data() {
    return {
      regionName: '',
      regionCode: this.$g.regionCode,
    };
  },
  created() {},
  mounted() {
    /* setTimeout(() => {
      this._getEventAlarm();
    }, 20); */
    /* this.$nextTick(() => {
      this._getEventAlarm();
    }); 地图未加载*/
    window.addEventListener('onmessageWS', this.getsocketData);
  },
  computed: {
    ...mapState({
      cameraMapList: (state) => state.aiCenter.cameraMapList,
    }),
  },
  watch: {
    cameraMapList(nv, ov) {
      if (nv != ov) {
        this._getEventAlarm();
      }
    },
  },
  methods: {
    getsocketData(e) {
      // 创建接收消息函数
      const data = e && JSON.parse(e.detail.data);
      //'G50沪渝高速渝长下行录制时长已超过30分钟，请及时处理！'
      if (data.type == 1) {
        this.$nextTick(() => {
          this._getEventAlarm();
        });
      }
    },
    _getEventAlarm() {
      this.$api.aiCenterY.queryCamerasOnMap().then((res) => {
        if (res.code === CODE_OK) {
          let list = res.data.map((item) => {
            item.type = '1';
            let lonLat = wgs84_to_gcj02(item.longitude, item.latitude);
            item.long = lonLat[0];
            item.lat = lonLat[1];
            return item;
          });
          this.$nextTick(() => {
            this.$refs.amapIns && this.$refs.amapIns.pointsSetIn([...list, ...this.cameraMapList]);
          });
          // this.setCameraMapList(data);
        }
      });
      // console.log('haha', this.$refs.amapIns, this.cameraMapList);
    },
    misHandle() {
      this._getEventAlarm();
      this.getAlarmList();
    },
    mapChanged() {},
    amapCompleteCallback() {
      this._getEventAlarm();
    },
    amapClickCallback() {},
    ...mapMutations(['setCameraMapList']),
    ...mapActions(['getAlarmList']),
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.resizeWindow.bind(this));
    // 在需要的时候卸载监听事件，比如离开页面
    window.removeEventListener('onmessageWS', this.getsocketData);
  },
};
</script>

<style scoped></style>
