<template>
  <div class="full-width full-height flex items-center">
    <div class="bot-left bg-f full-height p-sm">
      <bot-left />
    </div>
    <div class="bot-main flex-one bg-f m-l-sm m-r-sm full-height p-sm">
      <bot-main />
    </div>
    <div class="bot-right bg-f full-height p-sm">
      <bot-right />
    </div>
  </div>
</template>

<script>
import BotLeft from './BotLeft';
import BotRight from './BotRight';
import BotMain from './BotMain';
export default {
  name: 'BottomComponent',
  data() {
    return {};
  },
  components: {
    BotLeft,
    BotRight,
    BotMain,
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.bot-left,
.bot-right {
  width: 28%;
}
</style>
