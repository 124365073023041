<template>
  <div class="flex items-center full-height relative">
    <div class="f-left bg-f m-r-sm full-height p-sm">
      <top-left />
    </div>
    <div class="f-main flex-one bg-f full-height">
      <top-main />
    </div>
    <div class="f-right bg-f m-l-sm full-height">
      <top-right />
    </div>
  </div>
</template>

<script>
import TopLeft from './TopLeft';
import TopMain from './TopMain';
import TopRight from './TopRight';
export default {
  name: 'TopComponent',
  data() {
    return {};
  },
  components: {
    TopLeft,
    TopMain,
    TopRight,
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
.f-left,
.f-right {
  width: 28%;
}
</style>
