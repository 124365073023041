<template>
  <div class="full-width p-h-xs ht-style">
    <!--表格弹出框 -->
    
        <div class="event-alarm full-height">
          <div class="flex m-b-sm">
            <title-com text="路线报警统计" />
          </div>
          <template>
          <auth-mode v-if="!hasPermission(10921211)"></auth-mode>
          <template v-else>
              <div class="flex flex-one justify-between f12 m-b-sm">
                <span
                  class="flex-one column  p-xs text-center justify-center flex items-center  m-r-xs bg-f  text-center text-lightblue"
                  >路线</span
                >
                <span
                  class="flex-one column  p-xs text-center justify-center flex items-center  m-r-xs bg-f  text-center text-lightblue"
                  v-for="item in getColumns"
                  :key="item.eventType"
                  :title="item.typeName"
                >
                  {{ item.typeName }}
                </span>
              </div>
              <vue-scroll :ops="$root.scrollOpsY" class="scroll-ht">
                <div
                  class="flex items-center text-lightblue p-h-sm"
                  v-for="item in eventStatistic"
                  :key="item.roadId"
                >
                  <span
                    class="flex-one m-r-xs text-center pointer ellipsis"
                    :title="item['roadName']"
                  >
                    {{ item['roadName'] }}
                  </span>
                  <span
                    class="flex-one column-con m-r-xs text-center ellipsis pointer"
                    v-for="co in item.eventTypeNumber"
                    :key="co.eventType"
                    @click="openTable(item, co)"
                    >{{ co.num }}</span
                  >
                </div>
              </vue-scroll>
              <div
                v-show="eventStatistic.length <= 0"
                class="full-width flex items-center justify-center"
              >
                <a-spin />
              </div>
          </template>
        </template>
          <top-left-table-dialog
            :visible="tableVisible"
            :searchParams="searchParams"
            :title="title"
            @close="closeHandle"
            @misReportParent="_getEventStatistics"
          />
        </div>
     
  </div>
</template>
<script>
import TitleCom from './TitleCom';
import TopLeftTableDialog from './TopLeftTableDialog';
import AuthMode from '@/components/AuthMode'
export default {
  components: {
     TitleCom, 
     TopLeftTableDialog,
     AuthMode
  },
  data() {
    return {
      columns: [],
      tableVisible: false,
      warnlist: [],
      coId: '',
      roadId: '',
      title: '',
      eventStatistic: [],
      searchParams: {},
    };
  },
  computed: {
    styleObj() {
      return `${
        this.$g.isDepart
          ? 'height:calc(50% - 10px)'
          : 'height:calc(100% - 10px)'
      }`;
    },
    filtercolumns() {
      return this.columns.slice(1);
    },
    getColumns() {
      return this.eventStatistic.length
        ? this.eventStatistic[0]['eventTypeNumber'].map((item) => {
            item.typeName = item.typeName.slice(0, 4);
            return item;
          })
        : [];
    },
  },
  created() {
    // this._getTypeName();
    this._getEventStatistics();
  },
  methods: {
    _getEventStatistics() {
      this.$api.aiCenterY.getEventStatistics().then((res) => {
        if (res.code === 200) {
          this.eventStatistic = res.data;
        } else {
          this.$message.error('路线报警统计列表拉取失败!');
        }
      });
    },
    _getTypeName() {
      this.$api.aiCenterY.getTypeNameAll('1001').then((res) => {
        if (res.code === 200) {
          // console.error(res);
          let columns = res.data.map((item) => {
            item.eventName = item.eventName.slice(0, 4);
            return item;
          });
          columns.unshift({
            id: 0,
            eventName: '路线',
            type: 'route',
          });
          this.columns = columns;
          // console.error(this.columns);//表格的头标题 气象灾害  和id:100101
          this._getRoadWarnList();
        }
      });
    },
    _getRoadWarnList() {
      this.$api.aiCenterY.getEventStatistics().then((res) => {
        if (res.code === 200) {
          // console.error('haha', res.data);
          let arr = [];
          let datas = res.data;
          var Garr = datas.filter((item) => {
            return item.roadId.substr(0, 1) == 'S';
          });
          var Sarr = datas.filter((item) => {
            return item.roadId.substr(0, 1) == 'G';
          });
          var newG = Garr.sort(this.compare('roadId'));
          var newS = Sarr.sort(this.compare('roadId'));
          var newArr = newS.concat(newG);
          // console.log('111111111',newArr);
          // 调用compare函数
          arr = newArr.map((item) => {
            item.eventTypeNumber.forEach((et) => {
              // item[this.columns[idx + 1]['id']] = et;
              item[et['eventType']] = et.num;
            });
            // item[this.columns[0]['id']] = item.roadName;
            item['value0'] = item.roadName;
            //将获取得到的roadId通过组件传参
            this.roadId = item.roadId;
            return item;
          });
          //console.log('hhh',arr);
          this.warnlist = arr;
        }
      });
    },
    // 将G20排序的函数
    compare(property) {
      return function(a, b) {
        var value1 = a[property].substr(1);
        var value2 = b[property].substr(1);
        return value1 - value2;
      };
    },

    openTable(item, co) {
      
      if (!co.num) {
        return;
      }
      this.tableVisible = true;
      this.searchParams = {
        roadId: item.roadId,
        eventType: co.eventType,
      };
      this.title = `${item.roadName}-${co.typeName}统计`;
    },
    closeHandle() {
      this.tableVisible = false;
      this.searchParams = {};
      // this._getTypeName();
    },
  },
};
</script>
<style scoped>
.root {
  width: 60px;
  background: #96b9e4;
  opacity: 0.3;
  text-align: center;
}
.table-text {
  color: #86b3e9;
}
.ht-style {
  height: 50%;
}
.scroll-ht {
  height: calc(100% - 90px) !important;
}
.column:last-child,
.column-con:last-child {
  margin-right: 0;
}
</style>
